import "./PatientProgress.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useCallback, useContext, useEffect, useState } from "react";
import loginService from "../../services/login-service";
import userService from "../../services/user-service";
import { GlobalContext } from "../GlobalContext";
import ChevronRightIcon from "../../assets/imgs/chevron-right-icon.svg";

import DefaultUserIcon from "../../assets/imgs/default-user-icon.png";
import SuccessIcon from "../../assets/imgs/success-icon.svg";
import FlashGrayIcon from "../../assets/imgs/flash-gray.svg";
import FlashGreenIcon from "../../assets/imgs/flash-green.svg";
import FlashRedIcon from "../../assets/imgs/flash-red.svg";

import DefaultButton from "../../components/DefaultButton/DefaultButton";
import DefaultDropdown from "../../components/DefaultDropdown/DefaultDropdown";
import TextInput from "../../components/TextInput/TextInput";

import { useParams } from "react-router-dom";
import StatusTag from "../../components/StatusTag/StatusTag";
import LightMenu from "../../components/LightMenu/LightMenu";

import CaloriesIcon from "../../assets/imgs/calories-icon.svg";
import ProteinIcon from "../../assets/imgs/protein-icon.svg";
import SodiumIcon from "../../assets/imgs/sodium-icon.svg";
import CarboIcon from "../../assets/imgs/carbo-icon.svg";

import DefaultFoodstuffIcon from "../../assets/imgs/default-foodstuff-icon.svg";
import { ColumnHeader, ColumnValue } from "../../components/DefaultTable/DefaultTable";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const getMealFoodstuffNames = (meal) => {
  return meal.foodstuffs.map((foodstuff) => foodstuff.foodstuff.name).join(', ');
}

const dateString = (date) => {
  const formattedYear = date.getFullYear();
  const formattedMonth = date.getMonth() > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const formattedDay = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;

  return `${formattedYear}-${formattedMonth}-${formattedDay}`;
};

const formatDateTime = (dateTime) => {
  const date = new Date(dateTime);
  const localeTime = date.toLocaleTimeString('pt-BR');
  const [hour, minute] = localeTime.split(':');
  return `${hour}:${minute}`;
}

const getDate = (date) => {
  const [year, month, day] = String(date).split('-');
  return new Date(Number(year), Number(month) - 1, Number(day));
}

const getDaysFromWeek = (week) => {
  const startDate = getDate(week.start_date);
  let dateNumber = new Date(startDate).getDate();
  let lastDateNumber = getDate(week.end_date).getDate();
  const days = [];

  for (let i = dateNumber; i <= lastDateNumber; i++) {
    const date = getDate(week.start_date);
    date.setDate(i);

    days.push(date);
  }

  return days;
}

const days = [
  { name: "Domingo", shortName: "D" },
  { name: "Segunda", shortName: "S" },
  { name: "Terça", shortName: "T" },
  { name: "Quarta", shortName: "Q" },
  { name: "Quinta", shortName: "Q" },
  { name: "Sexta", shortName: "S" },
  { name: "Sábado", shortName: "S" },
];

const Metric = ({ icon, title, value }) => {
  return (
    <div className="patient__monthly-summary__day__content__metric">
      <div className="patient__monthly-summary__day__content__metric__icon">
        <img src={icon} alt="Calorias" />
      </div>
      <div className="patient__monthly-summary__day__content__metric__info">
        <span className="patient__monthly-summary__day__content__metric__title">{value}</span>
        <span className="patient__monthly-summary__day__content__metric__subtitle">{title}</span>
      </div>
    </div>
  )
}

const MonthlySummary = ({
  mealSummaryByDate,
  getStatusColor,
  getStatusTagTitle,
  dayWasFollowed,
  monthlySummary,
}) => {
  const [selectedWeek, setSelectedWeek] = useState();
  const [expandedDate, setExpandedDate] = useState();

  const handleSelectWeek = (week) => {
    setSelectedWeek(currentWeek => currentWeek === week ? undefined : week);
  }

  const mealColumnsWidth = {
    IMAGE: 1,
    NAME: 20,
    TIME: 5,
  }

  return (
    <div className="patient__monthly-summary">
      <div className="patient__monthly-summary__weeks">
        <div className="patient__monthly-summary__weeks__header">
          <h3>Média semanal de calorias</h3>
        </div>
        <div className="patient__monthly-summary__weeks__items">
          {monthlySummary?.weeks.map((week) => (
            <a
              key={week.week_index}
              href="javascript:;"
              className={`patient__monthly-summary__weeks__item ${selectedWeek?.week_index === week.week_index && 'patient__monthly-summary__weeks__item--selected'}`}
              onClick={() => handleSelectWeek(week)}
            >
              <div className="patient__monthly-summary__weeks__item__left">
                <div className="patient__monthly-summary__weeks__item__icon">
                  <img src={SuccessIcon} alt="Success Icon" />
                </div>
                <div className="patient__monthly-summary__weeks__item__info">
                  <p className="patient__monthly-summary__weeks__item__title">Semana {week.week_index + 1}</p>
                  <p className="patient__monthly-summary__weeks__item__calories">
                    {week.minimum_calories === week.maximum_calories ? week.minimum_calories : `${week.minimum_calories} a ${week.maximum_calories}`} kcal
                  </p>
                </div>
              </div>
              <div className="patient__monthly-summary__weeks__item__days">
                {getDaysFromWeek(week).map((day) => (
                  <div key={day} className="patient__monthly-summary__weeks__item__day">
                    <p>{days[day.getDay()]?.shortName}</p>
                    {mealSummaryByDate && dayWasFollowed(day) === true && <img src={FlashGreenIcon} alt="Flash Green Icon" />}
                    {mealSummaryByDate && dayWasFollowed(day) === false && <img src={FlashRedIcon} alt="Flash Green Icon" />}
                    {mealSummaryByDate && dayWasFollowed(day) === undefined && <img src={FlashGrayIcon} alt="Flash Green Icon" />}
                  </div>
                ))}
              </div>
            </a>
          ))}
        </div>
      </div>
      <div className="patient__monthly-summary__days">
        {selectedWeek && getDaysFromWeek(selectedWeek).map((day) => (
          <div key={day} className="patient__monthly-summary__day">
            <a
              href="javascript:;"
              className="patient__monthly-summary__day__header"
              onClick={() => setExpandedDate(currentDate => currentDate === dateString(day) ? undefined : dateString(day))}
            >
              <div className="patient__monthly-summary__day__info">
                <div className="patient__monthly-summary__day__info__text">
                  <h4>{days[day.getDay()].name} ({day.getDate()})</h4>
                  <p>{mealSummaryByDate && mealSummaryByDate[dateString(day)]?.total_of_calories || 0} kcal</p>
                </div>
                <div className="patient__monthly-summary__day__info__tag">
                  <StatusTag
                    title={getStatusTagTitle(day)}
                    color={getStatusColor(day)}
                    size="small"
                  />
                </div>
              </div>
              <div className={`patient__monthly-summary__day__toggle ${expandedDate !== dateString(day) ? 'patient__monthly-summary__day__toggle--hidden' : ''}`}>
                <img src={ChevronRightIcon} alt="Chevron Right Icon" />
              </div>
            </a>
            {mealSummaryByDate && mealSummaryByDate[dateString(day)] && (
              <div className={`${expandedDate === dateString(day) ? 'patient__monthly-summary__day__content' : 'patient__monthly-summary__day__content--hidden'}`}>
                <div className="patient__monthly-summary__day__content__metrics">
                  <Metric icon={CaloriesIcon} title="Calorias" value={mealSummaryByDate[dateString(day)]?.total_of_calories} />
                  <Metric icon={ProteinIcon} title="Proteínas" value={`${mealSummaryByDate[dateString(day)]?.total_of_proteins.toFixed(1)}g`} />
                  <Metric icon={SodiumIcon} title="Sódio" value={`${mealSummaryByDate[dateString(day)]?.total_of_sodium.toFixed(1)}mg`} />
                  <Metric icon={CarboIcon} title="Carboidratos" value={`${mealSummaryByDate[dateString(day)]?.total_of_carbohydrates.toFixed(1)}g`} />
                </div>
                <div className="patient__monthly-summary__day__content__title">
                  <h4>Refeições</h4>
                </div>
                <div className="patient__monthly-summary__day__content__items">
                  {mealSummaryByDate[dateString(day)]?.meals?.map((meal) => (
                    <div className="table__row daily-meal-summary__row">
                      <ColumnValue column="IMAGE" columnsWidth={mealColumnsWidth} className="patient__monthly-summary__day__content__icon">
                        <div className="patient__monthly-summary__day__content__icon">
                          <img src={meal?.foodstuffs[0].foodstuff.photo_url ?? DefaultFoodstuffIcon} alt="Foodstuff icon" />
                        </div>
                      </ColumnValue>
                      <ColumnValue column="NAME" columnsWidth={mealColumnsWidth} className="patient__monthly-summary__day__content__info">
                        <p className="patient__monthly-summary__day__content__title">{getMealFoodstuffNames(meal)}</p>
                        <p className="patient__monthly-summary__day__content__subtitle">{meal.total_of_calories} kcal</p>
                      </ColumnValue>
                      <ColumnValue column="TIME" columnsWidth={mealColumnsWidth} className="patient__monthly-summary__day__content__time">
                        {formatDateTime(meal.date_time)}
                      </ColumnValue>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

const WeeklySummary = ({
  mealSummaryByDate,
  getStatusColor,
  getStatusTagTitle,
  currentWeek,
}) => {
  const [expandedDate, setExpandedDate] = useState();

  const mealColumnsWidth = {
    IMAGE: 1,
    NAME: 20,
    TIME: 5,
  }

  return (
    <div className="patient__monthly-summary">
      <div className="patient__monthly-summary__days">
        {currentWeek && getDaysFromWeek(currentWeek).map((day) => (
          <div key={day} className="patient__monthly-summary__day">
            <a
              href="javascript:;"
              className="patient__monthly-summary__day__header"
              onClick={() => setExpandedDate(currentDate => currentDate === dateString(day) ? undefined : dateString(day))}
            >
              <div className="patient__monthly-summary__day__info">
                <div className="patient__monthly-summary__day__info__text">
                  <h4>{days[day.getDay()].name} ({day.getDate()})</h4>
                  <p>{mealSummaryByDate && mealSummaryByDate[dateString(day)]?.total_of_calories || 0} kcal</p>
                </div>
                <div className="patient__monthly-summary__day__info__tag">
                  <StatusTag
                    title={getStatusTagTitle(day)}
                    color={getStatusColor(day)}
                    size="small"
                  />
                </div>
              </div>
              <div className={`patient__monthly-summary__day__toggle ${expandedDate !== dateString(day) ? 'patient__monthly-summary__day__toggle--hidden' : ''}`}>
                <img src={ChevronRightIcon} alt="Chevron Right Icon" />
              </div>
            </a>
            {mealSummaryByDate && mealSummaryByDate[dateString(day)] && (
              <div className={`${expandedDate === dateString(day) ? 'patient__monthly-summary__day__content' : 'patient__monthly-summary__day__content--hidden'}`}>
                <div className="patient__monthly-summary__day__content__metrics">
                  <Metric icon={CaloriesIcon} title="Calorias" value={mealSummaryByDate[dateString(day)]?.total_of_calories} />
                  <Metric icon={ProteinIcon} title="Proteínas" value={`${mealSummaryByDate[dateString(day)]?.total_of_proteins.toFixed(1)}g`} />
                  <Metric icon={SodiumIcon} title="Sódio" value={`${mealSummaryByDate[dateString(day)]?.total_of_sodium.toFixed(1)}mg`} />
                  <Metric icon={CarboIcon} title="Carboidratos" value={`${mealSummaryByDate[dateString(day)]?.total_of_carbohydrates.toFixed(1)}g`} />
                </div>
                <div className="patient__monthly-summary__day__content__title">
                  <h4>Refeições</h4>
                </div>
                <div className="patient__monthly-summary__day__content__items">
                  {mealSummaryByDate[dateString(day)]?.meals?.map((meal) => (
                    <div className="table__row daily-meal-summary__row">
                      <ColumnValue column="IMAGE" columnsWidth={mealColumnsWidth} className="patient__monthly-summary__day__content__icon">
                        <div className="patient__monthly-summary__day__content__icon">
                          <img src={meal?.foodstuffs[0].foodstuff.photo_url ?? DefaultFoodstuffIcon} alt="Foodstuff icon" />
                        </div>
                      </ColumnValue>
                      <ColumnValue column="NAME" columnsWidth={mealColumnsWidth} className="patient__monthly-summary__day__content__info">
                        <p className="patient__monthly-summary__day__content__title">{getMealFoodstuffNames(meal)}</p>
                        <p className="patient__monthly-summary__day__content__subtitle">{meal.total_of_calories} kcal</p>
                      </ColumnValue>
                      <ColumnValue column="TIME" columnsWidth={mealColumnsWidth} className="patient__monthly-summary__day__content__time">
                        {formatDateTime(meal.date_time)}
                      </ColumnValue>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

const PeriodMetric = ({ periodName, value }) => {
  const periodsByName = {
    MORNING: {
      title: "Manhã",
      className: "square--red",
    },
    AFTERNOON: {
      title: "Tarde",
      className: "square--yellow",
    },
    NIGHT: {
      title: "Noite",
      className: "square--blue",
    },
  }
  const selectedPeriod = periodsByName[periodName];

  return (
    <div className="patient__summary__metrics__total__item">
      <div className="patient__summary__metrics__total__icon">
        <div className={`patient__summary__metrics__total__square ${selectedPeriod.className}`} />
      </div>
      <div className="patient__summary__metrics__total__info">
        <p className="patient__summary__metrics__total__title">{selectedPeriod.title}</p>
        <p className="patient__summary__metrics__total__subtitle">{value || 0} kcal</p>
      </div>
    </div>
  )
};

const DonutChart = ({ mealSummary }) => {
  const [data, setData] = useState([
    { label: "Morning", value: mealSummary?.calories_period?.morning, color: "#FE8398" },
    { label: "Afternoon", value: mealSummary?.calories_period?.afternoon, color: "#78C9FF" },
    { label: "Evening", value: mealSummary?.calories_period?.evening, color: "#FFD86E" },
  ]);

  const total = data.reduce((acc, item) => acc + item.value, 0);

  const [slices, setSlices] = useState([]);

  useEffect(() => {
    let startAngle = 0;
    const newSlices = data.map((item) => {
      const sliceAngle = (item.value / total) * 360;

      const background = `conic-gradient(
        ${item.color} 0deg ${sliceAngle}deg,
        transparent ${sliceAngle}deg 360deg
      )`;
      const transform = `rotate(${startAngle}deg)`;

      startAngle += sliceAngle;

      return (
        <div
          key={item.label}
          className="donut-chart__slice"
          style={{
            background,
            transform,
          }}
        />
      );
    });

    setSlices(newSlices);
  }, [data, total]);

  return (
    <div className="donut-chart">
      {slices}
      <div className="donut-chart__middle" />
      <div className="donut-chart__title">
        {mealSummary?.total_of_calories}
        <p className="donut-chart__subtitle">
          kcal
        </p>
      </div>
    </div>
  );
}

const MealSection = ({ mealSummary }) => {
  const columnsWidth = {
    IMAGE: 1.6,
    NAME: 3,
    CALORIES: 3,
    DATE: 3,
  }

  return (
    <div className="patient__meals">
      <div className="patient__meals__header">
        <div className="patient__meals__header__title">
          <h3>Refeições</h3>
        </div>
        <div className="patient__meals__header__last-meal">
          <p>Última refeição feita a 2 horas atrás</p>
        </div>
      </div>
      <div className="default-table patient-meals__table">
        <div className="table__header">
          <ColumnHeader title="Imagem" column="IMAGE" columnsWidth={columnsWidth} />
          <ColumnHeader title="Nome" column="NAME" columnsWidth={columnsWidth} />
          <ColumnHeader title="Horário" column="DATE" columnsWidth={columnsWidth} />
          <ColumnHeader title="Calorias" column="CALORIES" columnsWidth={columnsWidth} />
        </div>
        <div className="table__content">
          {mealSummary?.meals?.map((meal) => (
            <div className="table__row">
              <ColumnValue column="IMAGE" columnsWidth={columnsWidth}>
                <div className="patient__meals__foodstuff-icon">
                  <img src={meal.foodstuffs[0].foodstuff.photo_url ?? DefaultFoodstuffIcon} alt="Foodstuff icon" />
                </div>
              </ColumnValue>
              <ColumnValue column="NAME" columnsWidth={columnsWidth}>{getMealFoodstuffNames(meal)}</ColumnValue>
              <ColumnValue column="DATE" columnsWidth={columnsWidth}>{formatDateTime(meal.date_time)}</ColumnValue>
              <ColumnValue column="CALORIES" columnsWidth={columnsWidth}>{meal.total_of_calories} kcal</ColumnValue>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const PatientProgress = () => {
  const [patientId, setPatientId] = useState(useParams().id);
  const [currentSection, setCurrentSection] = useState("SHOW_MEAL_PLAN");
  const [mealSummary, setMealSummary] = useState();
  const [mealPlan, setMealPlan] = useState();
  const [patient, setPatient] = useState();
  const [monthlySummary, setMonthlySummary] = useState();
  const [mealSummaryByDate, setMealSummaryByDate] = useState();
  const [currentWeek, setCurrentWeek] = useState();
  const [summarySelected, setSummarySelected] = useState("DAILY");

  const { setSelectedScreen, isLoading, setIsLoading } = useContext(GlobalContext);

  const menuItems = [
    {
      code: "DAILY",
      title: 'Diário',
      onClick: () => setSummarySelected("DAILY")
    },
    {
      code: "WEEKLY",
      title: 'Semanal',
      onClick: () => setSummarySelected("WEEKLY")
    },
    {
      code: "MONTHLY",
      title: 'Mensal',
      onClick: () => setSummarySelected("MONTHLY")
    },
  ]

  const formatActivityFactor = (activityFactor) => {
    const formattedNames = {
      SEDENTARY: "Sedentário",
      LIGHTLY_ACTIVE: "Pouco ativo",
      MODERATELY_ACTIVE: "Moderadamente ativo",
      VERY_ACTIVE: "Muito ativo",
      EXTRA_ACTIVE: "Super ativo",
    }

    return formattedNames[activityFactor] || '';
  }

  const fetchPatient = useCallback(async (id) => {
    if (!isLoading) {
      setIsLoading(true);
    }

    const user = await userService.getUserById(id);
    setPatient(user);
  }, []);

  const fetchMealSummary = useCallback(async (userId, date) => {
    if (!isLoading) {
      setIsLoading(true);
    }

    const mealSummary = await userService.getMealSummary(userId, date);
    setMealSummary(mealSummary);

    setIsLoading(false);
  }, []);

  const fetchMonthlyMealSummary = useCallback(async (userId, month) => {
    if (!isLoading) {
      setIsLoading(true);
    }

    const mealSummary = await userService.getMonthlyMealSummary(userId, month);
    setMonthlySummary(mealSummary);

    const summaryByDate = new Map();
    const currentDate = new Date();

    console.log('amount of weeks', mealSummary?.weeks.length);

    for (let week of mealSummary?.weeks) {
      const startDate = getDate(week.start_date);
      const endDate = getDate(week.end_date);
      const dateIsBetween = currentDate >= startDate && currentDate <= endDate;

      if (dateIsBetween) {
        setCurrentWeek(week);
      }

      for (let day of week.days) {
        summaryByDate[day.date] = day;
      }
    }

    setMealSummaryByDate(summaryByDate);
  }, [])

  useEffect(() => {
    document.title = "Dashboard";
    setSelectedScreen("patient");

    async function fetchData() {
      setIsLoading(true);

      const currentDate = new Date();
      const formattedYear = currentDate.getFullYear();
      const formattedMonth = currentDate.getMonth() > 9 ? currentDate.getMonth() + 1 : `0${currentDate.getMonth() + 1}`;
      const formattedDay = currentDate.getDate() > 9 ? currentDate.getDate() : `0${currentDate.getDate()}`;

      const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;
      const yearMonth = `${formattedYear}-${formattedMonth}`;

      await Promise.all([
        fetchPatient(patientId),
        fetchMealSummary(patientId, formattedDate),
        fetchMonthlyMealSummary(patientId, yearMonth)
      ]);
      setIsLoading(false);
    }

    setIsLoading(true);
    fetchData();
  }, []);

  const dayWasFollowed = (day) => {
    const daySummary = mealSummaryByDate[dateString(day)];
    if (!daySummary) {
      return undefined;
    }

    const minCalories = daySummary.calories.required * 0.7;
    const maxCalories = daySummary.calories.required * 1.3;
    const acquiredCalories = daySummary.calories.acquired;

    return acquiredCalories >= minCalories && acquiredCalories <= maxCalories;
  }

  const getStatusTagTitle = (day) => {
    const dayWasFollowedValue = dayWasFollowed(day);

    if (dayWasFollowedValue === true) {
      return "Em Dia";
    } else if (dayWasFollowedValue === false) {
      return "Não seguindo";
    }

    return "Não registrado";
  }

  const getStatusColor = (day) => {
    const dayWasFollowedValue = dayWasFollowed(day);

    if (dayWasFollowedValue === true) {
      return "green";
    } else if (dayWasFollowedValue === false) {
      return "red";
    }

    return "gray";
  }

  const DailySummary = () => {
    return (
      <div className="patient__daily-summary">
        <div className="patient__summary__metrics">
          <div className="patient__summary__metrics__total">
            <div className="patient__summary__metrics__total__chart">
              {mealSummary && <DonutChart mealSummary={mealSummary} />}
            </div>
            <div className="patient__summary__metrics__total__division">
              <PeriodMetric periodName="MORNING" value={mealSummary?.calories_period.morning} />
              <PeriodMetric periodName="AFTERNOON" value={mealSummary?.calories_period.afternoon} />
              <PeriodMetric periodName="NIGHT" value={mealSummary?.calories_period.evening} />
            </div>
          </div>
          <div className="patient__summary__metrics__specific">
            <div className="patient__summary__metrics__specific__line">
              <div className="patient__summary__metrics__specific__item">
                <div className="patient__summary__metrics__specific__icon">
                  <img src={CaloriesIcon} alt="Calorias" />
                </div>
                <h4>{mealSummary?.total_of_calories}</h4>
                <p>Calorias</p>
              </div>
              <div className="patient__summary__metrics__specific__item">
                <div className="patient__summary__metrics__specific__icon">
                  <img src={ProteinIcon} alt="Proteínas" />
                </div>
                <h4>{mealSummary?.total_of_proteins.toFixed(1)} g</h4>
                <p>Proteínas</p>
              </div>
            </div>
            <div className="patient__summary__metrics__specific__line">
              <div className="patient__summary__metrics__specific__item">
                <div className="patient__summary__metrics__specific__icon">
                  <img src={SodiumIcon} alt="Sódio" />
                </div>
                <h4>{mealSummary?.total_of_sodium.toFixed(1)} mg</h4>
                <p>Sódio</p>
              </div>
              <div className="patient__summary__metrics__specific__item">
                <div className="patient__summary__metrics__specific__icon">
                  <img src={CarboIcon} alt="Carboidratos" />
                </div>
                <h4>{mealSummary?.total_of_carbohydrates.toFixed(1)} g</h4>
                <p>Carboidratos</p>
              </div>
            </div>
          </div>
        </div>
        <MealSection mealSummary={mealSummary} />
      </div>
    )
  }

  const PatientDetails = () => {
    return (
      <div className="patient__details">
        <div className="patient__details__name">
          <div className="patient__details__name__avatar">
            <img src={patient?.photo_url ?? DefaultUserIcon} alt="User Icon" />
          </div>
          <h2>{patient?.name}</h2>
        </div>
        <div className="patient__details__actions">
          {mealPlan && (
            <>
              <DefaultButton
                title={currentSection === "SHOW_MEAL_PLAN" ? "Editar plano alimentar" : "Salvar plano alimentar"}
                type="primary"
                onClick={() => {
                  setCurrentSection(currentSection === "SHOW_MEAL_PLAN" ? "EDIT_MEAL_PLAN" : "SHOW_MEAL_PLAN");
                }}
                size="small"
              />
              <DefaultButton
                title="Desativar plano alimentar"
                type="secondary"
                onClick={() => { }}
                size="small"
              />
            </>
          )}
        </div>
        <div className="patient__details__personal">
          <div className="patient__details__personal__title">
            <h3>Informações pessoais</h3>
          </div>
          <div className="patient__details__personal__forms">
            <TextInput
              label="Data de nascimento"
              placeholder="Data de nascimento"
              value={patient?.birth_date}
            />
            <div className="patient__details__personal__forms__measurements">
              <TextInput
                label="Peso"
                placeholder="Peso"
                value={`${patient?.body_measurement?.weight ?? ''}`}
              />
              <TextInput
                label="Altura"
                placeholder="Altura"
                value={`${patient?.body_measurement?.height || ''}`}
              />
            </div>
            <DefaultDropdown
              label="Nível de atividade física"
              value={formatActivityFactor(patient?.physical_activity_factor)}
              placeholder="Nível de atividade física"
              options={[
                { value: "SEDENTARY", label: "Sedentário" },
                { value: "LIGHTLY_ACTIVE", label: "Pouco ativo" },
                { value: "MODERATELY_ACTIVE", label: "Moderadamente ativo" },
                { value: "VERY_ACTIVE", label: "Muito ativo" },
                { value: "EXTRA_ACTIVE", label: "Super ativo" },
              ]}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="patient">
        <div className="patient__content">
          <Breadcrumb
            previousPage={{ href: "/dashboard", title: "Dashboard" }}
            currentPage={{ title: patient?.name }}
          />
          {patient && (
            <div className="patient__container">
              <PatientDetails />
              <div className="patient__summary">
                <div className="patient__summary__header">
                  <div className="patient__summary__header__title">
                    <h3>
                      Resumo
                    </h3>
                    <div className="patient__summary__header__subtitle">
                      <StatusTag title="Em Dia" size="small" />
                    </div>
                  </div>
                  <div className="patient__summary__header__menu">
                    <LightMenu
                      items={menuItems}
                      selectedCode={summarySelected}
                      size="small"
                    />
                  </div>
                </div>
                {summarySelected === "DAILY" && <DailySummary />}
                {summarySelected === "WEEKLY" && 
                <WeeklySummary 
                  mealSummaryByDate={mealSummaryByDate}
                  getStatusTagTitle={getStatusTagTitle}
                  getStatusColor={getStatusColor}
                  currentWeek={currentWeek}
                />
                }
                {summarySelected === "MONTHLY" &&
                  <MonthlySummary
                    mealSummaryByDate={mealSummaryByDate}
                    getStatusTagTitle={getStatusTagTitle}
                    getStatusColor={getStatusColor}
                    dayWasFollowed={dayWasFollowed}
                    monthlySummary={monthlySummary}
                  />}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientProgress;

PatientProgress.propTypes = {};
