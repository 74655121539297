import "./Avatar.css";

import React from "react";
import PropTypes from "prop-types";

import VerifiedIcon from "../../assets/imgs/verified-icon.png";

import "bootstrap/dist/css/bootstrap.min.css";

const NamingAvatar = ({ userName }) => {
  const names = userName?.split(" ");
  if (!names) {
    return <></>
  }
  const initials = names?.length > 1 ? names[0].charAt(0) + names[names.length - 1].charAt(0) : names[0].charAt(0);

  return (
    <a href="javascript:;" className="naming-avatar">
      {initials}
    </a>
  )
}

const Avatar = ({ user, verified, onClick = () => {} }) => {
  return (
    <a
      href="javascript:;"
      className="avatar"
      onClick={onClick}
    >
      {user?.photo_url ? (
        <img src={user?.photo_url} alt="Avatar" />
      ) : (
        <NamingAvatar userName={user?.name} />
      )}
      {verified && user?.photo_url && (
        <div className="avatar__verified">
          <img src={VerifiedIcon} alt="Verificado" />
        </div>
      )}
    </a>
  )
}

Avatar.propTypes = {
  previousPage: PropTypes.shape({
    title: PropTypes.string,
    href: PropTypes.string,
  }),
  currentPage: PropTypes.shape({
    title: PropTypes.string,
  }),
};

export default Avatar;