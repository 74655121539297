import axios from "axios";
import { getAPIUrl } from "../config";

const api = axios.create({
  baseURL: getAPIUrl(),
});

const registrationService = {
  async register(user) {
    const response = await api.post("/v1/registration", user);

    return response.data;
  },

  async confirmRegistration(registrationId, code) {
    const response = await api.post(
      `/v1/registration/${registrationId}/confirm`,
      {
        code,
      }
    );

    return response.data;
  },

  async resendCode(registrationId) {
    const response = await api.post(
      `/v1/registration/${registrationId}/resend-code`
    );

    return response.data;
  },
};

export default registrationService;