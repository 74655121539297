import "./NumericPagination.css";

import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import ChevronLeftIcon from "../../assets/imgs/chevron-left-icon.svg";
import ChevronRightIcon from "../../assets/imgs/chevron-right-icon.svg";

import "bootstrap/dist/css/bootstrap.min.css";

const NumericPagination = ({ currentPage, totalPages, onSelectPage, pagesPerView }) => {
  const pageIsUnavailable = (page) => !totalPages || page > totalPages;
  const shownPagesPerView = pagesPerView || 10;
  const [pages, setPages] = React.useState([]);

  const getPagesFrom = (page) => {
    const tenCurrentPages = [];
    let firstNumberOfTheTen;

    firstNumberOfTheTen = page - (page % shownPagesPerView) + 1;

    for (let i = firstNumberOfTheTen; i < firstNumberOfTheTen + shownPagesPerView; i++) {
      tenCurrentPages.push(i);
    }

    return tenCurrentPages;
  }

  const getPageItemStyle = (page) => {
    const itemStyle = "numeric-pagination__button";

    if (page === currentPage) {
      return `${itemStyle} selected-page`;
    }

    if (pageIsUnavailable(page)) {
      return `${itemStyle} disabled-page`;
    }

    return itemStyle;
  }

  const handleSelectPage = (page) => {
    if (pageIsUnavailable(page)) {
      return;
    }

    const isLastShownPage = page > pages[shownPagesPerView - 2];
    const isFirstShownPage = page < pages[0] - 1;

    if (isLastShownPage) {
      setPages(getPagesFrom(page + 1));
    } else if (isFirstShownPage) {
      setPages(getPagesFrom(page - 9));
    }

    onSelectPage(page);
  }

  useEffect(() => {
    setPages(getPagesFrom(currentPage - 1));
  }, []);

  return (
    <div className="numeric-pagination">
      {currentPage > 1 ? (
        <a
          href="javascript:;"
          className="numeric-pagination__button"
          onClick={() => handleSelectPage(currentPage - 2)}
        >
          <img src={ChevronLeftIcon} alt="Página anterior" />
        </a>
      ) : (
        <div className="numeric-pagination__button disabled-page">
          <img src={ChevronLeftIcon} alt="Página anterior" />
        </div>
      )}
      {pages?.map((page) => (
        <>
          {pageIsUnavailable(page) ? (
            <div
              key={page}
              className={getPageItemStyle(page)}
            >
              {page}
            </div>
          ) : (
            <a
              href="javascript:;"
              key={page}
              className={getPageItemStyle(page)}
              onClick={() => handleSelectPage(page - 1)}
            >
              {page}
            </a>
          )}
        </>
      ))}
      {currentPage < totalPages ? (
        <a
          href="javascript:;"
          className="numeric-pagination__button numeric-pagination__button--last next-page"
          onClick={() => handleSelectPage(currentPage)}
        >
          <img src={ChevronRightIcon} alt="Próxima página" />
        </a>
      ) : (
        <div className="numeric-pagination__button numeric-pagination__button--last disabled-page">
          <img src={ChevronRightIcon} alt="Próxima página" />
        </div>
      )}
    </div>
  )
}

NumericPagination.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onSelectPage: PropTypes.func,
  pagesPerView: PropTypes.number,
};

export default NumericPagination;