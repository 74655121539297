import axios from "axios";
import { getAPIUrl } from "../config";

const api = axios.create({
  baseURL: getAPIUrl(),
});

const connectionRequestService = {
  async sendInvite(nutritionistId, patientEmail) {
    const token = localStorage.getItem("token");

    const response = await api.post(
      `/v1/connection-requests`,
      {
        nutritionist_id: nutritionistId,
        patient_email: patientEmail,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  },
};

export default connectionRequestService;