import "./StatusTag.css";

import React from "react";
import PropTypes from "prop-types";

import "bootstrap/dist/css/bootstrap.min.css";
import GreenDotIcon from "../../assets/imgs/greendot.svg";

const StatusTag = ({
  title,
  color,
  size,
}) => {
  let colorClass = "";
  if (color === "green") {
    colorClass = "status-tag--green";
  }
  if (color === "red") {
    colorClass = "status-tag--red";
  }
  if (color === "gray") {
    colorClass = "status-tag--gray";
  }

  return (
    <div className={`status-tag ${size === 'small' && 'status-tag--small'} ${colorClass}`}>
      <div className="status-tag__icon">
        <div className="status-tag__circle" />
      </div>
      <div className="status-tag__title">
        <p>{title}</p>
      </div>
    </div>
  );
};

StatusTag.propTypes = {
  title: PropTypes.string.isRequired,
};

export default StatusTag;
