import "./Appointments.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import userService from "../../services/user-service";
import { GlobalContext } from "../GlobalContext";
import DefaultFoodstuffIcon from "../../assets/imgs/default-foodstuff-icon.svg";
import ChevronRightIcon from "../../assets/imgs/chevron-right-icon.svg";
import LightMenu from "../../components/LightMenu/LightMenu";
import StatusTag from "../../components/StatusTag/StatusTag";
import { ColumnHeader, ColumnValue } from "../../components/DefaultTable/DefaultTable";
import nutritionistService from "../../services/nutritionist-service";
import loginService from "../../services/login-service";

const Appointments = () => {
  const [usersResponse, setUsersResponse] = useState({});
  const [users, setUsers] = useState([]);
  const [currentUsersPage, setCurrentUsersPage] = useState(0);
  const { setSelectedScreen, isLoading, setIsLoading } = useContext(GlobalContext);

  const fetchPatients = useCallback(async (name, page) => {
    const nutritionist = await loginService.getCurrentUser();
    try {
      setIsLoading(true);
      const usersResponse = await nutritionistService.getPatients(nutritionist?.id, page ?? currentUsersPage, 8, name);
      setUsers(usersResponse.content);
      setCurrentUsersPage(page + 1);
      setUsersResponse(usersResponse);
    } catch (error) {
      console.log("Erro ao buscar pacientes", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    document.title = "Dashboard";
    setSelectedScreen("home");

    Promise.resolve(fetchPatients());
  }, []);

  const columnsWidth = {
    PATIENT: 2.5,
    TYPE: 2.5,
    STATUS: 2.5,
    DATE: 3,
    MORE: 0.6,
  }

  return (
    <div className="nutritionist">
      <div className="nutritionist__content">
        <div className="nutritionist__content__header">
          <div className="nutritionist__content__header__title">
            {usersResponse?.total_elements && <h1>{usersResponse?.total_elements || 0} Consultas Agendadas</h1>}
          </div>
          <div className="nutritionist__content__header__invite">
            <LightMenu
              items={[
                {
                  code: "12",
                  title: "12 meses",
                  onClick: () => (window.location.href = "/invite"),
                },
                {
                  code: "6",
                  title: "30 dias",
                  onClick: () => (window.location.href = "/invite"),
                },
                {
                  code: "7",
                  title: "7 dias",
                  onClick: () => (window.location.href = "/invite"),
                },
              ]}
              selectedCode="12"
            />
          </div>
        </div>
        <div className="default-table appointments__table">
          <div className="table__header">
            <ColumnHeader title="Paciente" column="PATIENT" columnsWidth={columnsWidth} />
            <ColumnHeader title="Tipo de Consulta" column="TYPE" columnsWidth={columnsWidth} />
            <ColumnHeader title="Status" column="STATUS" columnsWidth={columnsWidth} />
            <ColumnHeader title="Data e hora" column="DATE" columnsWidth={columnsWidth} />
            <ColumnHeader title="" column="MORE" columnsWidth={columnsWidth} />
          </div>
          <div className="table__content">
            {users?.map((user) => (
              <div className="table__row">
                <ColumnValue column="PATIENT" columnsWidth={columnsWidth}>
                  <div className="appointments__column__patient">
                    <div className="appointments__column__patient-icon">
                      <img src={user.photo_url ?? DefaultFoodstuffIcon} alt="Banana icon" />
                    </div>
                    <p>{user.name}</p>
                  </div>
                </ColumnValue>
                <ColumnValue column="TYPE" columnsWidth={columnsWidth}>Presencial</ColumnValue>
                <ColumnValue column="STATUS" columnsWidth={columnsWidth}>
                  <StatusTag title="Confirmado" />
                </ColumnValue>
                <ColumnValue column="DATE" columnsWidth={columnsWidth}>13 de Dezembro 13:00h</ColumnValue>
                <ColumnValue column="MORE" columnsWidth={columnsWidth} className="appointments__chevron">
                    <div className="home__nutritionist__users__item__actions__edit">
                      <Button
                        onClick={() =>
                          (window.location.href = `/appointments/${user.id}`)
                        }
                      >
                        <img src={ChevronRightIcon} alt="Right Arrow" />
                      </Button>
                    </div>
                </ColumnValue>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointments;

Appointments.propTypes = {};
