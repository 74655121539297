import "./DefaultModal.css";

import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";

import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

import CloseIconGray from "../../assets/imgs/close-icon-gray.svg";

const DefaultModal = ({
  title,
  showModal,
  onClose,
  children,
  className,
}) => {
  const [openModal, setOpenModal] = React.useState(showModal);

  const handleCloseModal = () => {
    setOpenModal(false);
    onClose();
  }


  useEffect(() => {
    setOpenModal(showModal);
  }, [showModal]);

  return (
    <>
      <div className="default-modal__modal">
        <Modal show={openModal} onHide={handleCloseModal} onExit={handleCloseModal} className={className || ''} >
          <div className="default-modal__modal__header">
            <h4>{title}</h4>
            <a 
              className="default-modal__modal__close"
              href="javascript:;"
              onClick={handleCloseModal}
            >
              <img src={CloseIconGray} alt="Fechar" onClick={handleCloseModal} />
            </a>
          </div>
          <div className="default-modal__modal__content">
            {children}
          </div>
        </Modal>
      </div >
    </>
  );
};

DefaultModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default DefaultModal;
