import "./NotificationBar.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useEffect, useState } from "react";
import TickCircleIcon from "../../assets/imgs/tick-circle-icon.svg";

const NotificationBar = ({
  title,
  durationInSeconds = 5,
  show = false,
  setShow = () => {},
  onClose = () => {},
}) => {
  const [hideNotificationBar, setHideNotificationBar] = useState(false);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setHideNotificationBar(true);
      }, (durationInSeconds-1)*1000)

      setTimeout(() => {
        setShow(false);
        setHideNotificationBar(false);
        onClose();
      }, durationInSeconds*1000);
    }
  }, [show]);

  useEffect(() => {
    setShow(show);
  }, [show]);

  return (
    <>
      {show && (
        <div className="bottom-notification">
          <div className={`bottom-notification__section ${hideNotificationBar ? 'bottom-notification__section--hidden' : ''}`}>
            <img src={TickCircleIcon} alt="Tick Circle Icon" />
            <p>{title}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default NotificationBar;
