import "./Breadcrumb.css";

import React, { useEffect } from "react";
import PropTypes from "prop-types";

import ChevronRightIcon from "../../assets/imgs/chevron-right-icon.svg";

import "bootstrap/dist/css/bootstrap.min.css";

const Breadcrumb = ({ previousPage, currentPage }) => {
  return (
    <div className="breadcrumb">
      <a href={previousPage.href} className="breadcrumb__previous">
        {previousPage.title}
      </a>
      <div className="breadcrumb__divisor">
        <img src={ChevronRightIcon} alt="Chevron Right Icon" />
      </div>
      <a href="#" className="breadcrumb__name">
        <p>{currentPage.title}</p>
      </a>
    </div>
  )
}

Breadcrumb.propTypes = {
  previousPage: PropTypes.shape({
    title: PropTypes.string,
    href: PropTypes.string,
  }),
  currentPage: PropTypes.shape({
    title: PropTypes.string,
  }),
};

export default Breadcrumb;