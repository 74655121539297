import axios from "axios";
import { getAPIUrl } from "../config";

const api = axios.create({
  baseURL: getAPIUrl(),
});

const loginService = {
  async login(email, password) {
    try {
      const response = await api.post("/login", {
        email,
        password,
      });

      
      localStorage.setItem("token", response.data);

      await this.updateUserStorage();

      return response.data;
    } catch (error) {
      throw new Error("Usuário ou senha inválidos");
    }
  },

  async saveTokenForGoogleAuthentication(token) {
    localStorage.setItem("token", token);

    try {
      await this.updateUserStorage();
    } catch (error) {
      await this.logout();
    }
  },
  
  async logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  },

  async updateUserStorage() {
    const token = localStorage.getItem("token");

    try {
      const response = await api.get("/v1/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      localStorage.setItem("user", JSON.stringify(response.data));

      return response.data;
    } catch (error) {
      throw new Error("Erro ao buscar informações do usuário");
    }
  },

  async getCurrentUser() {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");

    if (user === null || user.id === null || token === null) {
      return null;
    }

    return JSON.parse(user);
  },
};

export default loginService;