import "./NutritionistDashboard.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import loginService from "../../services/login-service";
import userService from "../../services/user-service";
import { GlobalContext } from "../GlobalContext";
import DefaultButton from "../../components/DefaultButton/DefaultButton";
import SuccessArrowUp from "../../assets/imgs/success-arrow-up.svg";
import ErrorArrowUp from "../../assets/imgs/error-arrow-up.svg";

import ChevronRightIcon from "../../assets/imgs/chevron-right-icon.svg";
import GreenDotIcon from "../../assets/imgs/greendot.svg";
import NumericPagination from "../../components/NumericPagination/NumericPagination";
import LightMenu from "../../components/LightMenu/LightMenu";
import nutritionistService from "../../services/nutritionist-service";
import DefaultModal from "../../components/DefaultModal/DefaultModal";
import TextInput from "../../components/TextInput/TextInput";
import avatarService from "../../services/avatar-service";
import NotificationBar from "../../components/NotificationBar/NotificationBar";

const MissingAvatarSection = ({ currentUser, setNotificationBar }) => {
  const [avatares, setAvatares] = useState([]);
  const [avatarsResponse, setAvatarsResponse] = useState();
  const [currentAvatarsPage, setCurrentAvatarsPage] = useState(1);
  const [tags, setTags] = useState();
  const [showSelectAvatarModal, setShowSelectAvatarModal] = useState(false);
  const [showAvatarReminder, setShowAvatarReminder] = useState(true);
  const { setIsLoading } = useContext(GlobalContext);

  const handleChooseAvatar = async (avatarId) => {
    try {
      setIsLoading(true);
      await userService.updatePhoto(currentUser?.id, avatarId);
      await loginService.updateUserStorage();
      setNotificationBar({ title: "Avatar atualizado com sucesso!" });
    } catch (error) {
      console.log('Error updating user avatar', error);
    } finally {
      setIsLoading(false);
      setShowSelectAvatarModal(false);
    }
  }

  const fetchAvatars = async (page) => {
    setIsLoading(true);
    const avatares = await avatarService.getPhotos(page, 12, tags, currentUser?.gender)

    setAvatarsResponse(avatares);
    setAvatares(avatares.content);
    setIsLoading(false);
  }

  useEffect(() => {
    if (currentUser) {
      Promise.resolve(fetchAvatars(currentAvatarsPage));
    }
  }, [currentUser, currentAvatarsPage, tags]);

  return (
    <>
      {currentUser && !currentUser?.photo_url && showAvatarReminder && (
        <div className="n-dashboard__avatar-warning">
          <h2 className="n-dashboard__avatar-warning__title">Que tal adicionar um avatar ao seu perfil?</h2>
          <p className="n-dashboard__avatar-warning__subtitle">
            Isso ajuda a tornar sua presença mais personalizada e amigável para seus pacientes
          </p>
          <div className="n-dashboard__avatar-warning__actions">
            <div className="n-dashboard__avatar-warning__actions__not-now">
              <DefaultButton
                title="Agora não"
                type="secondary"
                onClick={() => setShowAvatarReminder(false)}
                size="small"
              />
            </div>
            <div className="n-dashboard__avatar-warning__actions__add">
              <DefaultButton
                title="Escolher avatar"
                onClick={() => setShowSelectAvatarModal(true)}
                size="small"
              />
            </div>
          </div>
        </div>
      )}
      <DefaultModal
        title="Selecionar avatar"
        showModal={showSelectAvatarModal}
        onClose={() => setShowSelectAvatarModal(false)}
      >
        <div className="avatar-selector-modal">
          <div className="avatar-selector-modal__input">
            <TextInput
              label="URL do avatar"
              placeholder="Buscar por características..."
              onChangeText={(text) => setTags(text)}
            />
          </div>
          <div className="avatar-selector-modal__header">
            <div className="avatar-selector-modal__info">
              <p className="avatar-selector-modal__title">Avatares</p>
              <p className="avatar-selector-modal__amount">(20)</p>
            </div>
            <div className="avatar-selector-modal__button">
              {/* 
                TODO: allow this button in the future only for administrators
                <DefaultButton
                  title="Novo avatar"
                  onClick={() => { }}
                  size="small"
                  type="tertiary"
                /> */}
            </div>
          </div>
          <div className="avatar-selector-modal__content">
            {avatares.map((avatar) => (
              <a
                href="javascript:;"
                className="avatar-selector-modal__item"
                onClick={() => handleChooseAvatar(avatar.id)}
              >
                <img src={avatar.url} alt="Avatar" />
              </a>
            ))}
          </div>
          <div className="avatar-selector-modal__footer">
            <NumericPagination
              currentPage={currentAvatarsPage}
              totalPages={avatarsResponse?.total_pages}
              onSelectPage={(page) => setCurrentAvatarsPage(page + 1)}
            />
          </div>
        </div>
      </DefaultModal>
      {/* <NotificationBar 
        show={true}
        setShow={setShowSuccessNotificationBar}
        title="Avatar atualizado com sucesso!"
        durationInSeconds={500}
      /> */}
    </>
  )
}

const SummaryItem = ({
  title,
  value,
  variancePercentage,
  varianceDirection,
}) => {
  const varianceIcon = varianceDirection === "INCREASE" ? SuccessArrowUp : ErrorArrowUp;
  const varianceClass = `n-dashboard__summary__item__variance ${varianceDirection === "DECREASE" && "n-dashboard__summary__item__variance--decrease"}`;

  return (
    <div className="n-dashboard__summary__item">
      <div className="n-dashboard__summary__item__title">{title}</div>
      <div className="n-dashboard__summary__item__data">
        <div className="n-dashboard__summary__item__value">{value}</div>
        <div className={varianceClass}>
          <img
            src={varianceIcon}
            alt="Arrow"
          />
          <span>{variancePercentage}%</span>
        </div>
      </div>
    </div>
  )
}

const SummarySection = () => {
  const items = [
    {
      title: 'Consultas de hoje',
      value: 8,
      variance_percentage: '100',
      variance_direction: 'INCREASE'
    },
    {
      title: 'Novos pacientes',
      value: 43,
      variance_percentage: '100',
      variance_direction: 'INCREASE'
    },
    {
      title: 'Adesão aos planos alimentares',
      value: 138,
      variance_percentage: '24',
      variance_direction: 'DECREASE'
    }
  ]

  return (
    <div className="n-dashboard__summary">
      {items.map((item, index) => (
        <SummaryItem
          key={index}
          title={item.title}
          value={item.value}
          variancePercentage={item.variance_percentage}
          varianceDirection={item.variance_direction}
        />
      ))}
    </div>
  )
}

const MealPlansSection = () => {
  const menuItems = [
    {
      code: "SHOW_ALL",
      title: 'Ver todos',
      onClick: () => { }
    },
    {
      code: "HIGHLIGHTS",
      title: 'Destaques',
      onClick: () => { }
    },
    {
      code: "LOW_ADHERENCE",
      title: 'Adesão Baixa',
      onClick: () => { }
    },
  ]

  return (
    <div className="n-dashboard__meal-plans">
      <div className="n-dashboard__meal-plans__header">
        <div className="n-dashboard__meal-plans__header__title">
          <h2>Planos alimentares em foco</h2>
          <span className="n-dashboard__meal-plans__header__patients">
            138 pacientes
          </span>
        </div>
        <p className="n-dashboard__meal-plans__header__subtitle">Veja o andamento dos planos alimentares.</p>
      </div>
      <div className="n-dashboard__meal-plans__actions">
        <LightMenu items={menuItems} selectedCode={"SHOW_ALL"} />
      </div>
      <PatiensList />
    </div>
  )
}

const PatiensList = () => {
  const [users, setUsers] = useState([]);
  const [usersResponse, setUsersResponse] = useState();
  const [currentUsersPage, setCurrentUsersPage] = useState(0);
  const [searchedUserName, setSearchedUserName] = useState("");
  const { setSelectedScreen, isLoading, setIsLoading } = useContext(GlobalContext);

  const fetchUsers = useCallback(async (name, page) => {
    if (!isLoading) {
      setIsLoading(true);
    }
    const nutritionist = await loginService.getCurrentUser();
    const usersResponse = await nutritionistService.getPatients(nutritionist?.id, page ?? currentUsersPage, 8, name);
    setUsersResponse(usersResponse);
    setUsers(usersResponse.content);
    setCurrentUsersPage(page + 1);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    Promise.resolve(fetchUsers("", 0));
  }, []);

  return (
    <div className="n-dashboard__patients">
      <div className="n-dashboard__patients__header">
        <div className="n-dashboard__patients__header__name">Nome</div>
        <div className="n-dashboard__patients__header__birthdate">Data de nascimento</div>
        <div className="n-dashboard__patients__header__height">Status de Adesão</div>
        <div className="n-dashboard__patients__header__last-update">Última atualização</div>
        <div className="n-dashboard__patients__header__weight">Nível de atividade física</div>
        <div className="n-dashboard__patients__header__actions" />
      </div>
      <div className="nutritionist__users">
        {users.map((user) => (
          <div className="nutritionist__users__item">
            <div className="nutritionist__users__item__name">
              <div className="nutritionist__users__item__image">
                <img src={user.photo_url ?? ''} alt="Banana icon" />
              </div>
              <p>{user.name}</p>
            </div>
            <div className={`n-dashboard__patients__birthdate ${!user.birth_date && 'nutritionist__users__item--not-appliable'}`}>
              {user?.birth_date ? new Date(user.birth_date).toLocaleDateString() : 'Não informado'}
            </div>
            <div className="n-dashboard__patients__meal-plan">
              <div className="nutritionist__users__item__meal-plan-item">
                <div className="nutritionist__users__item__meal-plan-dot">
                  <img src={GreenDotIcon} alt="Green Dot" />
                </div>
                <div className="nutritionist__users__item__meal-plan-text">
                  <p>Ativo</p>
                </div>
              </div>
            </div>
            <div className="n-dashboard__patients__last-update">
              <p>13 de Dezembro</p>
            </div>
            <div className="n-dashboard__patients__weight">
              <p>Reavaliar calorias</p>
            </div>
            <div className="n-dashboard__patients__actions">
              <div className="home__nutritionist__users__item__actions__edit">
                <Button
                  onClick={() =>
                    (window.location.href = `/patients/${user.id}/progress`)
                  }
                >
                  <img src={ChevronRightIcon} alt="Right Arrow" />
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="nutritionist__users__footer">
        {currentUsersPage && currentUsersPage <= usersResponse.total_pages && (
          <NumericPagination
            currentPage={currentUsersPage === 0 ? 1 : Number(currentUsersPage)}
            totalPages={usersResponse.total_pages}
            onSelectPage={(page) => {
              fetchUsers(searchedUserName, page);
            }}
          />
        )}
      </div>
    </div>
  )
}

const NutritionistDashboard = () => {
  const [currentUser, setCurrentUser] = useState();
  const [notificationBar, setNotificationBar] = useState(null);
  const { setSelectedScreen, setIsLoading } = useContext(GlobalContext);

  useEffect(() => {
    document.title = "Dashboard";
    setSelectedScreen("nutritionist-dashboard");

    async function fetchCurrentUser() {
      setIsLoading(true);
      const userFound = await loginService.getCurrentUser();
      setCurrentUser(userFound);
    }

    async function fetchData() {
      await fetchCurrentUser();
    }

    Promise.resolve(fetchData());
  }, []);

  return (
    <>
      <div className="n-dashboard">
        <div className="n-dashboard__title">
          <h1>Bem vindo de volta, {currentUser?.name}</h1>
        </div>
        <MissingAvatarSection 
          currentUser={currentUser} 
          setNotificationBar={setNotificationBar}
        />
        <SummarySection />
        <MealPlansSection />
      </div>
      <NotificationBar 
        show={notificationBar?.title}
        title={notificationBar?.title}
        durationInSeconds={4}
        onClose={() => setNotificationBar(null)}
      />
    </>
  );
};

export default NutritionistDashboard;

NutritionistDashboard.propTypes = {};
