import "./AvatarSelector.css";

import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import "bootstrap/dist/css/bootstrap.min.css";
import DefaultModal from "../DefaultModal/DefaultModal";
import TextInput from "../TextInput/TextInput";
import NumericPagination from "../NumericPagination/NumericPagination";
import { GlobalContext } from "../../pages/GlobalContext";
import userService from "../../services/user-service";
import loginService from "../../services/login-service";
import avatarService from "../../services/avatar-service";

const AvatarSelector = ({ 
  show,
  user,
  setNotificationBar,
  onClose = () => {},
}) => {
  const [showSelectAvatarModal, setShowSelectAvatarModal] = useState(show);
  const [avatares, setAvatares] = useState([]);
  const [avatarsResponse, setAvatarsResponse] = useState();
  const [currentAvatarsPage, setCurrentAvatarsPage] = useState(1);
  const [tags, setTags] = useState();
  const { setIsLoading } = useContext(GlobalContext);

  const handleChooseAvatar = async (avatarId) => {
    try {
      setIsLoading(true);
      await userService.updatePhoto(user?.id, avatarId);
      await loginService.updateUserStorage();
      setNotificationBar({ title: "Avatar atualizado com sucesso!" });
    } catch (error) {
      console.log('Error updating user avatar', error);
    } finally {
      setIsLoading(false);
      setShowSelectAvatarModal(false);
    }
  }

  const fetchAvatars = async (page) => {
    setIsLoading(true);
    const avatares = await avatarService.getPhotos(page, 12, tags, user?.gender)

    setAvatarsResponse(avatares);
    setAvatares(avatares.content);
    setIsLoading(false);
  }

  useEffect(() => {
    Promise.resolve(fetchAvatars(currentAvatarsPage));
  }, [currentAvatarsPage, tags]);

  useEffect(() => {
    setShowSelectAvatarModal(show);
  }, [show]);

  useEffect(() => {
    if (!showSelectAvatarModal) {
      onClose();
    }
  }, [showSelectAvatarModal])

  return (
    <DefaultModal
      title="Selecionar avatar"
      showModal={showSelectAvatarModal}
      onClose={() => setShowSelectAvatarModal(false)}
    >
      <div className="avatar-selector-modal">
        <div className="avatar-selector-modal__input">
          <TextInput
            label="URL do avatar"
            placeholder="Buscar por características..."
            onChangeText={(text) => setTags(text)}
          />
        </div>
        <div className="avatar-selector-modal__header">
          <div className="avatar-selector-modal__info">
            <p className="avatar-selector-modal__title">Avatares</p>
            <p className="avatar-selector-modal__amount">(20)</p>
          </div>
        </div>
        <div className="avatar-selector-modal__content">
          {avatares.map((avatar) => (
            <a
              href="javascript:;"
              className="avatar-selector-modal__item"
              onClick={() => handleChooseAvatar(avatar.id)}
            >
              <img src={avatar.url} alt="Avatar" />
            </a>
          ))}
        </div>
        <div className="avatar-selector-modal__footer">
          <NumericPagination
            currentPage={currentAvatarsPage}
            totalPages={avatarsResponse?.total_pages}
            onSelectPage={(page) => setCurrentAvatarsPage(page + 1)}
          />
        </div>
      </div>
    </DefaultModal>
  )
}

AvatarSelector.propTypes = {
  show: PropTypes.bool,
  user: PropTypes.object,
  setNotificationBar: PropTypes.func,
};

export default AvatarSelector;