import "./LightMenu.css";

import React from "react";
import PropTypes from "prop-types";

import "bootstrap/dist/css/bootstrap.min.css";

const LightMenu = ({ items, selectedCode, size }) => {
  const classBySize = () => {
    var className = "light-menu";

    if (size === "small") {
      return `${className} light-menu--small`;
    }

    return className;
  };
  
  return (
    <div className={classBySize()}>
      {items.map((item, index) => (
        <a
          key={index}
          href="#"
          className={`light-menu__item ${selectedCode === item.code && "light-menu__item--selected"}`}
          onClick={item.onClick}
        >
          <span>{item.title}</span>
        </a>
      ))}
    </div>
  )
};

LightMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  selectedTitle: PropTypes.string,
  size: PropTypes.string,
};

export default LightMenu;
