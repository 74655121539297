import "./UpdateFoodstuff.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useContext, useEffect, useState } from "react";
import brandService from "../../services/brand-service";
import foodstuffService from "../../services/foodstuff-service";
import SuccessModal from "../../components/SuccessModal/SuccessModal";
import ArrowLeftIcon from "../../assets/imgs/arrow-left-icon.svg";

import { useLocation, useParams } from "react-router-dom";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import { GlobalContext } from "../GlobalContext";
import DefaultButton from "../../components/DefaultButton/DefaultButton";
import TextInput from "../../components/TextInput/TextInput";
import ImageSelector from "../../components/ImageSelector/ImageSelector";
import DefaultDropdown from "../../components/DefaultDropdown/DefaultDropdown";
import CreateBrandModal from "../../components/CreateBrandModal/CreateBrandModal";
import WeightConverter from "../../components/WeightConverter/WeightConverter";

const foodGroups = [
  {
    name: "Frutas",
    value: "FRUITS",
  },
  {
    name: "Vegetais",
    value: "VEGETABLES",
  },
  {
    name: "Carnes",
    value: "MEATS",
  },
  {
    name: "Laticínios",
    value: "DAIRY",
  },
  {
    name: "Grãos",
    value: "GRAINS",
  },
  {
    name: "Legumes",
    value: "LEGUMES",
  },
  {
    name: "Oleos",
    value: "OILS",
  },
  {
    name: "Doces",
    value: "SWEETS",
  },
  {
    name: "Bebidas",
    value: "BEVERAGES",
  },
  {
    name: "Água",
    value: "WATER",
  },
  {
    name: "Outros",
    value: "OTHERS",
  },
];
const unitsOfMeasurement = [
  {
    name: "Unidades",
    value: "UNITS",
  },
  {
    name: "Gramas",
    value: "GRAMS",
  },
  {
    name: "Kilogramas",
    value: "KILOGRAMS",
  },
  {
    name: "Litros",
    value: "Liters",
  },
  {
    name: "Mililitros",
    value: "MILLILITERS",
  },
];

const UpdateFoodstuff = () => {
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [selectedUnitOfMeasurement, setSelectedUnitOfMeasuremnet] = useState();
  const [showBrandDropdown, setShowBrandDropdown] = useState(false);
  const [typedBrand, setTypedBrand] = useState("");
  const [foodstuff, setFoodstuff] = useState({});
  const [nutritionInformation, setNutritionInformation] = useState({});
  const [foodstuffId, setFoodstuffId] = useState(useParams().id);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorIsAdjustable, setErrorIsAdjustable] = useState(true);
  const [selectedPhoto, setSelectedPhoto] = useState();
  const [initialPhotoUrl, setInitialPhotoUrl] = useState();
  const [showCreateBrandModal, setShowCreateBrandModal] = useState(false);
  const [showWeightConverter, setShowWeightConverter] = useState(false);
  const foodstuffPageParam = new URLSearchParams(useLocation().search)?.get("currentPage");
  const withBarcodeParam = new URLSearchParams(useLocation().search)?.get("withBarcode");
  const withBrandParam = new URLSearchParams(useLocation().search)?.get("withBrand");

  const { setSelectedScreen, setIsLoading } = useContext(GlobalContext);

  const successfullyUpdatedDescription = (
    <>
      <p>
        O alimento <b>{foodstuff?.name}</b> foi modificado com sucesso. Você
        pode exibi-lo ou continuar editando.
      </p>
    </>
  );

  const fetchBrands = async (name) => {
    try {
      const brandsResponse = await brandService.getBrands(0, 5, name);
      setBrands(brandsResponse.content);
    } catch (error) {
      setShowErrorModal(true);
      setErrorMessage("Erro ao buscar marcas. Tente novamente ou contacte um administrator.");
      setErrorIsAdjustable(true);
    }
  };

  const fetchCurrentFoodstuff = async () => {
    try {
      setIsLoading(true);
      const foodstuffResponse = await foodstuffService.getFoodstuffById(foodstuffId);

      setFoodstuff((prevState) => ({
        ...prevState,
        brand_id: foodstuffResponse?.brand?.id,
        name: foodstuffResponse.name,
        group: foodstuffResponse.group,
        unit_of_measurement: foodstuffResponse.unit_of_measurement,
        baseline_weight: foodstuffResponse.baseline_weight,
        barcode: foodstuffResponse?.barcode,
      }));
      setInitialPhotoUrl(foodstuffResponse?.photo_url);
      setTypedBrand(foodstuffResponse?.brand?.name || "");
      setSelectedBrand(foodstuffResponse?.brand || undefined);
      setSelectedUnitOfMeasuremnet(
        unitsOfMeasurement.find(
          (unit) => unit.value === foodstuffResponse.unit_of_measurement
        )
      );
      setSelectedGroup(
        foodGroups.find((group) => group.value === foodstuffResponse.group)
      );
      setNutritionInformation(foodstuffResponse.nutrition_information);
    } catch {
      setShowErrorModal(true);
      setErrorMessage("Não existe nenhum alimento com o identificador fornecido. Volte para a listagem de alimentos e escolha um alimento válido para edição.");
      setErrorIsAdjustable(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateFoodstuff = async () => {
    const body = { ...foodstuff, photo_id: selectedPhoto?.id, nutrition_information: nutritionInformation };
    try {
      await foodstuffService.updateFoodstuff(foodstuffId, body);
      setShowSuccessModal(true);
    } catch (err) {
      console.log("error while updating foodstuff", err);
    }
  };

  const nutritionalInfoIsFilled = (nutritionalInfo) => {
    return nutritionalInfo !== undefined && nutritionalInfo !== null && nutritionalInfo !== "";
  }

  const nutritionInfoWasFilled = () =>
    nutritionalInfoIsFilled(nutritionInformation.calories) &&
    nutritionalInfoIsFilled(nutritionInformation.proteins) &&
    nutritionalInfoIsFilled(nutritionInformation.carbohydrates) &&
    nutritionalInfoIsFilled(nutritionInformation.sodium) &&
    nutritionalInfoIsFilled(nutritionInformation.fiber) &&
    nutritionalInfoIsFilled(nutritionInformation.fats);

  const foodstuffFieldsWereFilled = () =>
    foodstuff.name &&
    foodstuff.group &&
    foodstuff.unit_of_measurement &&
    foodstuff.baseline_weight;

  const allFieldsWereFilled = () =>
    nutritionInfoWasFilled() && foodstuffFieldsWereFilled();

  const handleSelectPhoto = (photo) => {
    setSelectedPhoto(photo);
  }

  const handleConvertWeightToUnit = (unitWeight) => {
    const proportion = unitWeight / foodstuff.baseline_weight;

    setNutritionInformation({
      ...nutritionInformation,
      calories: (nutritionInformation.calories * proportion).toFixed(1),
      proteins: (nutritionInformation.proteins * proportion).toFixed(1),
      carbohydrates: (nutritionInformation.carbohydrates * proportion).toFixed(1),
      sodium: (nutritionInformation.sodium * proportion).toFixed(1),
      fiber: (nutritionInformation.fiber * proportion).toFixed(1),
      fats: (nutritionInformation.fats * proportion).toFixed(1),
    });

    setFoodstuff({
      ...foodstuff,
      baseline_weight: unitWeight,
    });

    setSelectedUnitOfMeasuremnet(unitsOfMeasurement.find((unit) => unit.value === "UNITS"));
  }

  const getParams = () => {
    if (!foodstuffPageParam && !withBarcodeParam && !withBrandParam) {
      return "";
    }

    return `?${foodstuffPageParam ? `&currentPage=${foodstuffPageParam}` : ""}${withBarcodeParam !== undefined ? `&withBarcode=${withBarcodeParam}` : ""}${withBrandParam !== undefined ? `&withBrand=${withBrandParam}` : ""}`;
  };

  useEffect(() => {
    Promise.resolve(fetchCurrentFoodstuff());
  }, []);

  useEffect(() => {
    document.title = "Editar alimento";
    setSelectedScreen("update-foodstuff");
  }, []);

  return (
    <>
      <div className="update-foodstuff">
        <div className="new-foodstuff">
          <div className="top-menu__title">
            <a href="javascript:;" onClick={() => {
              window.location.href = `/foodstuffs${foodstuffPageParam && `${getParams()}`}`;
            }}>
              <div className="top-menu__back-screen">
                <img src={ArrowLeftIcon} alt="Arrow Left Icon" />
              </div>
              <h1>Editar alimento</h1>
            </a>
          </div>
          <div className="new-foodstuff__content">
            <div className="new-foodstuff__basic-information">
              <div className="new-foodstuff__basic-information__content">
                <div className="new-foodstuff__basic-information__content__select-image">
                  <ImageSelector
                    initialPhotoUrl={initialPhotoUrl}
                    onSelect={(photo) => handleSelectPhoto(photo)}
                  />
                </div>
                <div className="new-foodstuff__basic-info__right">
                  <div className="new-foodstuff__basic-info__content__name">
                    <TextInput
                      label="Nome"
                      placeholder="Ex: frango temperado"
                      value={foodstuff?.name}
                      onChangeText={(text) => setFoodstuff({ ...foodstuff, name: text })}
                    />
                  </div>
                  <div className="new-foodstuff__basic-info__content__group">
                  </div>
                  <div className="new-foodstuff__basic-info__right__second-line">
                    <div className="update-foodstuff__basic-info__group">
                      <DefaultDropdown
                        placeholder="Selecione um grupo alimentar"
                        value={selectedGroup?.name}
                        options={foodGroups.map((group) => group.name)}
                        onSelect={(group) => {
                          const groupFound = foodGroups.find((g) => g.name === group);
                          setSelectedGroup(groupFound);
                          setFoodstuff({ ...foodstuff, group: groupFound.value });
                        }}
                      />
                    </div>
                    <div className="update-foodstuff__basic-info__measurement">
                      <DefaultDropdown
                        placeholder="Selecione uma forma de medida"
                        value={selectedUnitOfMeasurement?.name}
                        options={unitsOfMeasurement.map((unit) => unit.name)}
                        onSelect={(unit) => {
                          const unitFound = unitsOfMeasurement.find((u) => u.name === unit);
                          setSelectedUnitOfMeasuremnet(unitFound);
                          setFoodstuff({ ...foodstuff, unit_of_measurement: unitFound.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="new-foodstuff__basic-info__right__third-line">
                    <div className="update-foodstuff__basic-info__weight">
                      <TextInput
                        label="Peso base (g)"
                        placeholder="Peso base (g)"
                        value={`${foodstuff?.baseline_weight || ''}` ?? ""}
                        onChangeText={(text) =>
                          setFoodstuff({ ...foodstuff, baseline_weight: text })
                        }
                      />
                    </div>
                    <div className="update-foodstuff__basic-info__barcode">
                      <TextInput
                        label="Código de barras"
                        placeholder="Código de barras"
                        value={`${foodstuff?.barcode || ''}` ?? ""}
                        onChangeText={(text) =>
                          setFoodstuff({ ...foodstuff, barcode: text })
                        }
                      />
                    </div>
                    <div className="new-foodstuff__basic-info__brand">
                      <DefaultDropdown
                        placeholder="Selecione uma marca"
                        value={selectedBrand?.name}
                        options={brands.map((brand) => brand.name)}
                        onSelect={(brand) => {
                          const brandFound = brands.find((b) => b.name === brand);
                          setSelectedBrand(brandFound);
                          setTypedBrand(brandFound.name);
                          setFoodstuff({ ...foodstuff, brand_id: brandFound.id });
                        }}
                        searchPlaceholder="Buscar marca"
                        onClickCreate={() => {
                          setShowCreateBrandModal(true);
                        }}
                        onSearch={(text) => {
                          setShowBrandDropdown(true);
                          setTypedBrand(text);
                          fetchBrands(text);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="new-foodstuff__nutritional-info">
              <div className="nutritional-info__title">
                <h3>Informações nutricionais</h3>
              </div>
              <div className="nutritional-info__content">
                <div className="nutritional-info__content__calories">
                  <TextInput
                    label="Calorias"
                    placeholder="Calorias"
                    value={`${nutritionInformation?.calories ?? ''}`}
                    onChangeText={(text) =>
                      setNutritionInformation({ ...nutritionInformation, calories: text })
                    }
                  />
                </div>
                <div className="nutritional-info__content__proteins">
                  <TextInput
                    label="Proteínas (g)"
                    placeholder="Proteínas (g)"
                    value={`${nutritionInformation?.proteins ?? ""}`}
                    onChangeText={(text) =>
                      setNutritionInformation({ ...nutritionInformation, proteins: text })
                    }
                  />
                </div>
                <div className="nutritional-info__content__carbohydrates">
                  <TextInput
                    label="Carboidratos (g)"
                    placeholder="Carboidratos (g)"
                    value={`${nutritionInformation?.carbohydrates ?? ""}`}
                    onChangeText={(text) =>
                      setNutritionInformation({ ...nutritionInformation, carbohydrates: text })
                    }
                  />
                </div>
                <div className="nutritional-info__content__sodium">
                  <TextInput
                    label="Sódio (g)"
                    placeholder="Sódio (g)"
                    value={`${nutritionInformation?.sodium ?? ""}`}
                    onChangeText={(text) =>
                      setNutritionInformation({ ...nutritionInformation, sodium: text })
                    }
                  />
                </div>
                <div className="nutritional-info__content__fibers">
                  <TextInput
                    label="Fibras (g)"
                    placeholder="Fibras (g)"
                    value={`${nutritionInformation?.fiber ?? ""}`}
                    onChangeText={(text) =>
                      setNutritionInformation({ ...nutritionInformation, fiber: text })
                    }
                  />
                </div>
                <div className="nutritional-info__content__saturated-fats">
                  <TextInput
                    label="Gordura totais (g)"
                    placeholder="Gordura totais (g)"
                    value={`${nutritionInformation?.fats ?? ""}`}
                    onChangeText={(text) =>
                      setNutritionInformation({ ...nutritionInformation, fats: text })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="new-foodstuff__footer">
              {selectedUnitOfMeasurement && selectedUnitOfMeasurement?.value !== "UNITS" && (
                <div className="new-foodstuff__footer__convert">
                  <DefaultButton
                    title="Converter para unidade"
                    onClick={() => setShowWeightConverter(true)}
                    disabled={
                      !foodstuff?.baseline_weight ||
                      !nutritionInformation?.calories ||
                      !nutritionInformation?.proteins ||
                      !nutritionInformation?.carbohydrates ||
                      !nutritionInformation?.sodium ||
                      !nutritionInformation?.fiber ||
                      !nutritionInformation?.fats
                    }
                    type="secondary"
                  />
                </div>
              )}
              <div className="new-foodstuff__create-button">
                <DefaultButton
                  title="Atualizar alimento"
                  onClick={() => handleUpdateFoodstuff()}
                  disabled={!allFieldsWereFilled()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        title="Alimento atualizado!"
        description={successfullyUpdatedDescription}
        confirmTitle="Exibir alimento"
        backTitle="Continuar editando"
        onConfirm={() => (window.location.href = `/foodstuffs${foodstuffPageParam && `?currentPage=${foodstuffPageParam}`}`)}
        onBack={() => setShowSuccessModal(false)}
        onExit={() => setShowSuccessModal(false)}
        show={showSuccessModal}
      />
      <ErrorModal
        title="Erro ao modificar alimento"
        description={errorMessage}
        confirmTitle={errorIsAdjustable ? "Tentar novamente" : "Voltar"}
        backTitle={errorIsAdjustable ? "Voltar" : ""}
        onConfirm={() => handleUpdateFoodstuff()}
        onBack={() => {
          if (!errorIsAdjustable) {
            window.location.href = `/foodstuffs${foodstuffPageParam && `?currentPage=${foodstuffPageParam}`}`;
          }
          setShowErrorModal(false);
        }}
        onExit={() => {
          if (!errorIsAdjustable) {
            window.location.href = `/foodstuffs${foodstuffPageParam && `?currentPage=${foodstuffPageParam}`}`;
          }
          setShowErrorModal(false);
        }}
        show={showErrorModal}
      />
      <CreateBrandModal
        showModal={showCreateBrandModal}
        onClose={() => setShowCreateBrandModal(false)}
      />
      <WeightConverter
        showModal={showWeightConverter}
        onClose={() => setShowWeightConverter(false)}
        onConvert={(unitWeight) => handleConvertWeightToUnit(unitWeight)}
      />
    </>
  );
};

export default UpdateFoodstuff;
