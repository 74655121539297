import "./Appointment.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useCallback, useContext, useEffect, useState } from "react";
import loginService from "../../services/login-service";
import userService from "../../services/user-service";
import foodstuffService from "../../services/foodstuff-service";
import { GlobalContext } from "../GlobalContext";
import ArrowLeftIcon from "../../assets/imgs/arrow-left-icon.svg";
import XSquareIcon from "../../assets/imgs/x-square-icon.svg";
import ChevronRightIcon from "../../assets/imgs/chevron-right-icon.svg";

import DefaultUserIcon from "../../assets/imgs/default-user-icon.png";
import MealPlanIcon from "../../assets/imgs/meal-plan-icon.svg";
import PinkDishIcon from "../../assets/imgs/pink-dish-icon.svg";

import DefaultButton from "../../components/DefaultButton/DefaultButton";
import DefaultDropdown from "../../components/DefaultDropdown/DefaultDropdown";
import TextInput from "../../components/TextInput/TextInput";

import { useParams } from "react-router-dom";
import DefaultModal from "../../components/DefaultModal/DefaultModal";

const mealOptions = [
  {
    value: "BREAKFAST",
    label: "Café da manhã",
  },
  {
    value: "MORNING_SNACK",
    label: "Lanche da manhã",
  },
  {
    value: "LUNCH",
    label: "Almoço",
  },
  {
    value: "AFTERNOON_SNACK",
    label: "Lanche da tarde",
  },
  {
    value: "DINNER",
    label: "Jantar",
  },
  {
    value: "SUPPER",
    label: "Ceia",
  },
]

const Appointment = () => {
  const [currentUser, setCurrentUser] = useState();
  const [patientId, setPatientId] = useState(useParams().id);
  const [currentSection, setCurrentSection] = useState("SHOW_MEAL_PLAN");
  const [searchedFoodstuffs, setSearchedFoodstuffs] = useState([]);
  const [selectedFoodstuff, setSelectedFoodstuff] = useState();
  const [selectedMealId, setSelectedMealId] = useState();
  const [showAddFoodstuffModal, setShowAddFoodstuffModal] = useState(false);

  const [mealPlan, setMealPlan] = useState();
  const [patient, setPatient] = useState();
  const { setSelectedScreen, isLoading, setIsLoading } = useContext(GlobalContext);

  const formatActivityFactor = (activityFactor) => {
    const formattedNames = {
      SEDENTARY: "Sedentário",
      LIGHTLY_ACTIVE: "Pouco ativo",
      MODERATELY_ACTIVE: "Moderadamente ativo",
      VERY_ACTIVE: "Muito ativo",
      EXTRA_ACTIVE: "Super ativo",
    }

    return formattedNames[activityFactor];
  }

  const formatMealName = (mealName) => {
    if (!mealName) {
      return '';
    }

    const formattedNames = {
      BREAKFAST: "Café da manhã",
      MORNING_SNACK: "Lanche da manhã",
      LUNCH: "Almoço",
      AFTERNOON_SNACK: "Lanche da tarde",
      DINNER: "Jantar",
      SUPPER: "Ceia",
    }

    return formattedNames[mealName] || mealName;
  }

  const getMeasurementPrefix = (unitOfMeasurement) => {
    const formattedNames = {
      GRAMS: "g",
      MILLILITERS: "ml",
      UNITS: "un",
    }

    return formattedNames[unitOfMeasurement] || unitOfMeasurement;
  }

  const formatFoodstuffTitle = (foodstuff) => {
    if (foodstuff.unit_of_measurement === 'UNITS') {
      return `${foodstuff.amount} ${foodstuff.name}`;
    }

    return `${foodstuff.baseline_weight}${getMeasurementPrefix(foodstuff.unit_of_measurement)} de ${foodstuff.name}`;
  }

  const fetchPatient = useCallback(async (id) => {
    if (!isLoading) {
      setIsLoading(true);
    }

    const user = await userService.getUserById(id);
    setPatient(user);
  }, []);

  const fetchFoodstuffs = useCallback(async (name) => {
    const foodstuffsResponse = await foodstuffService.getFoodstuffs(0, 4, name);

    setSearchedFoodstuffs(foodstuffsResponse.content);
  }, []);

  const availableMealOptions = mealOptions?.filter((option) => !mealPlan?.meals?.find((meal) => meal.name === option.value));

  useEffect(() => {
    document.title = "Dashboard";
    setSelectedScreen("patient");

    async function fetchCurrentUser() {
      if (!isLoading) {
        setIsLoading(true);
      }

      const userFound = await loginService.getCurrentUser();
      setCurrentUser(userFound);
    }

    async function fetchData() {
      setIsLoading(true);
      await Promise.all([
        fetchCurrentUser(),
        fetchPatient(patientId),
      ]);
      setIsLoading(false);
    }

    setIsLoading(true);
    fetchData();
    fetchFoodstuffs('');
  }, []);

  return (
    <>
      <div className="appointment">
        <div className="appointment__content">
          <div className="appointment__content__header">
            <a href="/appointments" className="appointment__content__header__previous">
              Consultas
            </a>
            <div className="appointment__content__header__divisor">
              <img src={ChevronRightIcon} alt="Chevron Right Icon" />
            </div>
            <div className="appointment__content__header__name">
              <p>{patient?.name}</p>
            </div>
          </div>
          {patient && (
            <div className="appointment__container">
              <div className="appointment__patient">
                <div className="patient__details__name">
                  <div className="patient__details__name__avatar">
                    <img src={patient?.photo_url ?? DefaultUserIcon} alt="User Icon" />
                  </div>
                  <h2>{patient?.name}</h2>
                </div>
                <div className="patient__details__actions">
                  {mealPlan && (
                    <>
                      <DefaultButton
                        title={currentSection === "SHOW_MEAL_PLAN" ? "Editar plano alimentar" : "Salvar plano alimentar"}
                        type="primary"
                        onClick={() => {
                          setCurrentSection(currentSection === "SHOW_MEAL_PLAN" ? "EDIT_MEAL_PLAN" : "SHOW_MEAL_PLAN");
                        }}
                        size="small"
                      />
                      <DefaultButton
                        title="Desativar plano alimentar"
                        type="secondary"
                        onClick={() => { }}
                        size="small"
                      />
                    </>
                  )}
                </div>
                <div className="patient__details__personal">
                  <div className="patient__details__personal__title">
                    <h3>Informações pessoais</h3>
                  </div>
                  <div className="patient__details__personal__forms">
                    <TextInput
                      label="Data de nascimento"
                      placeholder="Data de nascimento"
                      value={patient?.birth_date}
                    />
                    <div className="patient__details__personal__forms__measurements">
                      <TextInput
                        label="Peso"
                        placeholder="Peso"
                        value={`${patient?.body_measurement?.weight ?? ''}`}
                      />
                      <TextInput
                        label="Altura"
                        placeholder="Altura"
                        value={`${patient?.body_measurement?.height || ''}`}
                      />
                    </div>
                    <DefaultDropdown
                      label="Nível de atividade física"
                      value={formatActivityFactor(patient?.physical_activity_factor)}
                      options={[
                        { value: "SEDENTARY", label: "Sedentário" },
                        { value: "LIGHTLY_ACTIVE", label: "Pouco ativo" },
                        { value: "MODERATELY_ACTIVE", label: "Moderadamente ativo" },
                        { value: "VERY_ACTIVE", label: "Muito ativo" },
                        { value: "EXTRA_ACTIVE", label: "Super ativo" },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="appointment__details">
                <div className="appointment__details__content">
                  <div className="appointment__details__title">
                    <h3>Detalhes da consulta</h3>
                  </div>
                  <div className="appointment__details__when">
                    <div className="appointment__details__when__title">
                      <h4>Quando</h4>
                    </div>
                    <div className="appointment__details__when__date">
                      <p>14 de Janeiro de 2024</p>
                    </div>
                  </div>
                  <div className="appointment__details__time">
                    <div className="appointment__details__time__title">
                      <h4>Horário</h4>
                    </div>
                    <div className="appointment__details__time__hour">
                      <p>14:00</p>
                    </div>
                  </div>
                  <div className="appointment__details__where">
                    <div className="appointment__details__where__title">
                      <h4>Local</h4>
                    </div>
                    <div className="appointment__details__where__address">
                      <p>Rua do Nutricionista, 123</p>
                    </div>
                  </div>
                </div>
                <div className="appointment__details__actions">
                  <div className="appointment__details__actions__cancel">
                    <DefaultButton
                      title="Desmarcar"
                      type="secondary"
                      onClick={() => { }}
                      size="small"
                    />
                  </div>
                  <div className="appointment__details__actions__reschedule">
                    <DefaultButton
                      title="Remarcar"
                      type="primary"
                      onClick={() => { }}
                      size="small"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <DefaultModal
        showModal={showAddFoodstuffModal}
        title="Novo alimento"
        onClose={() => {
          setShowAddFoodstuffModal(false);
          setSelectedFoodstuff(undefined);
        }}
      >
        <div className="add-foodstuff-modal__content">
          <DefaultDropdown
            label="Buscar alimento"
            placeholder="Buscar alimento"
            value={selectedFoodstuff?.name || ""}
            options={
              searchedFoodstuffs.map((foodstuff) => ({
                value: foodstuff.id,
                label: foodstuff.name,
              }))
            }
            onSelect={(value) => {
              setSelectedFoodstuff(searchedFoodstuffs.find((foodstuff) => foodstuff.id === value));
            }}
            searchPlaceholder="Buscar alimento"
            onSearch={(text) => fetchFoodstuffs(text)}
          />
          {selectedFoodstuff && (
            <div className="add-foodstuff-modal__inputs">
              <TextInput
                label="Peso base (g)"
                placeholder="Peso base (g)"
                onChangeText={(text) => { }}
                value={`${selectedFoodstuff.baseline_weight || ''}`}
              />
              <TextInput
                label="Calorias"
                placeholder="Calorias"
                onChangeText={(text) => { }}
                value={`${selectedFoodstuff?.nutrition_information?.calories || ''}`}
              />
              {selectedFoodstuff.unit_of_measurement === 'UNITS' && (
                <TextInput
                  label="Quantidade"
                  placeholder="Quantidade"
                  onChangeText={(text) => { }}
                  value={`${selectedFoodstuff.amount || '1'}`}
                />
              )}
            </div>
          )}
        </div>
        <div className="add-foodstuff-modal__buttons">
          <div className="add-foodstuff-modal__button">
            <DefaultButton
              title="Cancelar"
              onClick={() => {
                setShowAddFoodstuffModal(false);
                setSelectedFoodstuff(undefined);
                setSelectedMealId(undefined);
              }}
              type="secondary"
              size="medium"
            />
          </div>
          <div className="add-foodstuff-modal__button">
            <DefaultButton
              title="Adicionar"
              onClick={() => {
                const newFoodstuff = {
                  ...selectedFoodstuff,
                  amount: selectedFoodstuff.unit_of_measurement === 'UNITS' ? selectedFoodstuff.amount : 1,
                };

                const newMealPlan = {
                  ...mealPlan,
                  meals: mealPlan.meals.map((meal) => meal.id === selectedMealId ? { ...meal, foodstuffs: [...meal.foodstuffs, newFoodstuff] } : meal),
                };

                setMealPlan(newMealPlan);
                setShowAddFoodstuffModal(false);
                setSelectedFoodstuff(undefined);
                setSelectedMealId(undefined);
              }}
              type="primary"
              size="medium"
              disabled={!selectedFoodstuff}
            />
          </div>
        </div>
      </DefaultModal>
    </>
  );
};

export default Appointment;

Appointment.propTypes = {};
