import axios from "axios";
import { getAPIUrl } from "../config";

const api = axios.create({
  baseURL: getAPIUrl(),
});

const userService = {
  async getUsers(page, size, name) {
    const token = localStorage.getItem("token");

    try {
      const response = await api.get("/v1/users", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          name,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Erro ao buscar usuários");
    }
  },
  async getUserById(id) {
    const token = localStorage.getItem("token");

    try {
      const response = await api.get(`/v1/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Erro ao buscar usuários");
    }
  },

  async getMealSummary(userId, date) {
    const token = localStorage.getItem("token");

    try {
      const response = await api.get(`/v1/users/${userId}/meals?date=${date}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Erro ao buscar resumo de refeições");
    }
  },

  async getMonthlyMealSummary(userId, yearMonth) {
    const token = localStorage.getItem("token");

    try {
      const response = await api.get(`/v1/users/${userId}/meal-reports?year_month=${yearMonth}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Erro ao buscar resumo de refeições mensal");
    }
  },

  async updatePhoto(userId, photoId) {
    const token = localStorage.getItem("token");

    const response = await api.put(`/v1/users/${userId}/photos/${photoId}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
};

export default userService;