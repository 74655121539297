import "./NutritionistRegistration.css";
import "bootstrap/dist/css/bootstrap.min.css";

import TextLogo from "../../assets/imgs/text-logo.svg";
import SuccessIcon from "../../assets/imgs/success-icon.svg";

import React, { useEffect, useState } from "react";
import TextInput from "../../components/TextInput/TextInput";
import DefaultDropdown from "../../components/DefaultDropdown/DefaultDropdown";
import DefaultButton from "../../components/DefaultButton/DefaultButton";
import RedWarningCircle from "../../assets/imgs/red-warning-circle.svg";
import registrationService from "../../services/registration-service";
import { GlobalContext } from "../GlobalContext";

const errorMessage = (error) => {
  const errorData = error?.response?.data || {};
  const errorDetails = errorData?.detail;
  const defaultMessage = 'Erro ao realizar cadastro. Tente novamente mais tarde.';
  const messagesByCode = {
    'NUTRITIONIST_NOT_REGISTERED_IN_CFN': 'A inscrição e o CRN informados não foram encontrados no Conselho Federal de nutrição (CFN). Por favor, verifique os dados inseridos e tente novamente.',
    'INVALID_DATA': 'Os dados informados são inválidos. Por favor, verifique os dados inseridos e tente novamente.',
    'EMAIL_ALREADY_REGISTERED': 'O e-mail informado já está cadastrado. Por favor, insira um e-mail diferente.',
    "INVALID_CONFIRMATION_CODE": "O código de confirmação informado é inválido. Por favor, verifique o código inserido e tente novamente.",
  }

  return messagesByCode[errorData.code || ''] || errorDetails || defaultMessage;
}

const StepMenu = ({
  stepsAmount,
  currentStep,
  setCurrentStep,
}) => {
  const [steps, setSteps] = useState(Array.from({ length: stepsAmount }, (_, i) => i + 1));

  const Step = ({stepNumber}) => {
    if (stepNumber > currentStep) {
      return (
        <a
          key={stepNumber}
          className="steps__step"
        />
      );
    }

    return (
      <a
        key={stepNumber}
        href="javascript:;"
        className={"steps__step--active"}
        onClick={() => setCurrentStep(stepNumber)}
      />
    );
  }

  return (
    <div className="steps">
      {steps.map((step) => <Step key={step} stepNumber={step} />)}
    </div>
  )
}

const BasicInformationStep = ({
  nutritionist,
  setNutritionist,
  handleNext,
}) => {
  const genders = [
    {
      name: "Masculino",
      value: 'MALE',
    },
    {
      name: "Feminino",
      value: 'FEMALE',
    },
  ];

  const genderByCode = {
    MALE: "Masculino",
    FEMALE: "Feminino",
  }

  const requiredInformation = ["name", "birth_date", "gender"]
  const informationIsMissing = requiredInformation.some((info) => !nutritionist[info]) || nutritionist.birth_date.length !== 10;

  return (
    <div className="default-step">
      <h1 className="default-step__title">Vamos começar com suas informações básicas!</h1>
      <div className="default-step__forms">
        <div className="default-step__form">
          <TextInput
            label="Nome Completo"
            placeholder="Nome Completo"
            value={nutritionist.name}
            onChangeText={(text) => setNutritionist({ ...nutritionist, name: text })}
            type="text"
          />
        </div>
        <div className="default-step__form">
          <TextInput
            label="Data de Nascimento"
            placeholder="Data de Nascimento"
            value={nutritionist.birth_date}
            onChangeText={(text) => setNutritionist({ ...nutritionist, birth_date: text })}
            type="date"
          />
        </div>
        <div className="default-step__form">
          <DefaultDropdown
            placeholder="Gênero"
            value={nutritionist.gender ? genderByCode[nutritionist.gender] : undefined}
            options={genders.map((group) => group.name)}
            onSelect={(genderName) => {
              const genderFound = genders.find((gender) => gender.name === genderName);
              setNutritionist({
                ...nutritionist,
                gender: genderFound.value,
              });
            }}
          />
        </div>
      </div>
      <div className="default-step__button">
        <DefaultButton
          title="Continuar"
          onClick={handleNext}
          disabled={informationIsMissing}
        />
      </div>
      <div className="default-step__footer">
        <p>Ao clicar em "Continuar", eu concordo com os <a href="#">Termos de Uso</a> e a <a href="#">Política de Privacidade</a> da Lorie.</p>
      </div>
    </div>
  )
}

const emailIsValid = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,6}$/;
  return emailRegex.test(email);
}

const EmailStep = ({
  nutritionist,
  setNutritionist,
  handleNext,
}) => {
  const requiredInformation = ["email"]
  const informationIsMissing = requiredInformation.some((info) => !nutritionist[info]) || !emailIsValid(nutritionist.email);

  return (
    <div className="default-step">
      <h1 className="default-step__title">Qual é o seu e-mail?</h1>
      <p className="default-step__title__subtitle">Enviaremos um código de verificação para seu email</p>
      <div className="default-step__forms">
        <div className="default-step__form">
          <TextInput
            label="E-mail"
            placeholder="E-mail"
            value={nutritionist.email || ""}
            onChangeText={(text) => setNutritionist({ ...nutritionist, email: text })}
          />
        </div>
      </div>
      <div className="default-step__button">
        <DefaultButton
          title="Continuar"
          onClick={handleNext}
          disabled={informationIsMissing}
        />
      </div>
    </div>
  )
}

const PasswordStep = ({
  nutritionist,
  setNutritionist,
  handleNext,
}) => {
  const requiredInformation = ["password"]
  const passwordIsWeak = (nutritionist.password || "").length < 8
    || !/[A-Z]/.test(nutritionist.password)
    || !/[a-z]/.test(nutritionist.password)
    || !/[0-9]/.test(nutritionist.password);
  const informationIsMissing = requiredInformation.some((info) => !nutritionist[info]) || passwordIsWeak;

  return (
    <div className="default-step">
      <h1 className="default-step__title">Crie uma senha segura</h1>
      <p className="default-step__title__subtitle">Para manter sua conta protegida, escolha uma senha única e forte.</p>
      <div className="default-step__forms">
        <div className="default-step__form">
          <TextInput
            label="Senha"
            placeholder="Senha"
            type="password"
            secureTextEntry={true}
            value={nutritionist?.password || ""}
            invalid={(nutritionist.password || "").length >= 1 && passwordIsWeak}
            onChangeText={(text) => setNutritionist({ ...nutritionist, password: text || "" })}
            invalidMessage="A senha deve conter no mínimo 8 caracteres, uma letra maiúscula, uma letra minúscula e um número."
          />
        </div>
      </div>
      <div className="default-step__button">
        <DefaultButton
          title="Continuar"
          onClick={handleNext}
          disabled={informationIsMissing}
        />
      </div>
    </div>
  )
}

const InscriptionStep = ({
  nutritionist,
  setNutritionist,
  setRegistration,
  handleNext,
}) => {
  const [crnNotFound, setCrnNotFound] = useState(true);
  const [error, setError] = useState();
  const { setIsLoading } = React.useContext(GlobalContext);

  const handleRegister = async () => {
    try {
      setIsLoading(true);
      const formattedNutritionist = {
        ...nutritionist,
        birth_date: nutritionist.birth_date.split('/').reverse().join('-'),
      }
      const response = await registrationService.register(formattedNutritionist);
      setRegistration(response);
      handleNext();
    } catch (error) {
      console.log('error while registering nutritionist', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  const crns = [
    {
      name: "CRN-1",
      value: 1,
    },
    {
      name: "CRN-2",
      value: 2,
    },
    {
      name: "CRN-3",
      value: 3,
    },
    {
      name: "CRN-4",
      value: 4,
    },
    {
      name: "CRN-5",
      value: 5,
    },
    {
      name: "CRN-6",
      value: 6,
    },
    {
      name: "CRN-7",
      value: 7,
    },
    {
      name: "CRN-8",
      value: 8,
    },
    {
      name: "CRN-9",
      value: 9,
    },
    {
      name: "CRN-10",
      value: 10,
    },
    {
      name: "CRN-11",
      value: 11,
    },
  ]
  const crnTypeByNumber = {
    1: "CRN-1",
    2: "CRN-2",
    3: "CRN-3",
    4: "CRN-4",
    5: "CRN-5",
  }

  const requiredInformation = ["crn", "registration_number"]
  const crnHasInvalidLength = () => {
    const number = nutritionist.nutritionist_details?.registration_number || "";
    return number.length > 0 && (number.length < 4 || number.length > 6);
  }; 
  const informationIsMissing = !nutritionist.nutritionist_details ||
    requiredInformation.some((info) => !nutritionist.nutritionist_details[info]) ||
    crnHasInvalidLength();

  return (
    <div className="default-step">
      <h1 className="default-step__title">Estamos quase lá!</h1>
      <p className="default-step__title__subtitle">Esses detalhes ajudam a confirmar que você é um nutricionista e a finalizar a criação da sua conta.</p>
      <div className="default-step__forms">
        <div className="default-step__form">
          <TextInput
            type="number"
            label="Inscrição"
            placeholder="Inscrição"
            description={!crnHasInvalidLength() && "Seu número de registro profissional."}
            value={nutritionist.nutritionist_details?.registration_number}
            invalid={crnHasInvalidLength()}
            invalidMessage="O número de inscrição deve conter entre 4 a 6 caracteres."
            maxLength={6}
            onChangeText={(text) => {
              setNutritionist({
                ...nutritionist,
                nutritionist_details: {
                  ...nutritionist.nutritionist_details,
                  registration_number: text,
                }
              })

              setCrnNotFound(false);
            }}
          />
        </div>
        <div className="default-step__form inscription-step__form--crn">
          <DefaultDropdown
            placeholder="CRN"
            description="Seu Conselho Regional de Nutrição para confirmar sua prática."
            value={nutritionist.nutritionist_details?.crn ? crnTypeByNumber[nutritionist.nutritionist_details?.crn] : undefined}
            options={crns.map((crn) => crn.name)}
            onSelect={(crnName) => {
              const crnFound = crns.find((crn) => crn.name === crnName);
              setNutritionist({
                ...nutritionist,
                nutritionist_details: {
                  ...nutritionist.nutritionist_details,
                  crn: crnFound.value,
                },
              });
              setCrnNotFound(false);
            }}
          />
        </div>
      </div>
      {error && (
        <div className="inscription-step__error">
          <div className="inscription-step__error__icon">
            <img src={RedWarningCircle} alt="Warning" />
          </div>
          <div className="inscription-step__error__message">
            <p>{errorMessage(error)}</p>
          </div>
        </div>
      )}
      <div className="default-step__button">
        <DefaultButton
          title="Finalizar cadastro"
          onClick={handleRegister}
          disabled={informationIsMissing}
        />
      </div>
    </div>
  )
}

const VerificationStep = ({
  registration,
  handleNext,
}) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [codeWasSent, setCodeWasSent] = useState(true);
  const [error, setError] = useState();
  const [timer, setTimer] = useState(60);
  const { setIsLoading } = React.useContext(GlobalContext);

  const informationIsMissing = verificationCode.length !== 4;

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer((currTimer) => currTimer - 1);
      }, 1000);
    }
  }, [timer]);

  const handleConfirmRegistration = async () => {
    try {
      setIsLoading(true);
      await registrationService.confirmRegistration(registration.id, verificationCode);
      handleNext();
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleResendCode = async () => {
    try {
      setIsLoading(true);
      await registrationService.resendCode(registration.id);
      setTimer(60);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="default-step">
      <h1 className="default-step__title">Insira o código de verificação</h1>
      <p className="default-step__title__subtitle">Enviamos um código para o seu email. Não encontrou? Verifique a caixa de spam ou solicite um novo envio.</p>
      <div className="default-step__forms">
        <div className="verification-step__form">
          <input
            type="text"
            value={verificationCode}
            onChange={(event) => {
              if (event.target.value.length > 4) {
                return;
              }
              setVerificationCode(event.target.value);
            }}
            style={verificationCode.length === 4 ? { caretColor: "transparent" } : {}}
          />
          <div className="verification-step__dashes">
            <div className="verification-step__dash" />
            <div className="verification-step__dash" />
            <div className="verification-step__dash" />
            <div className="verification-step__dash" />
          </div>
        </div>
      </div>
      {error && (
        <div className="inscription-step__error">
          <div className="inscription-step__error__icon">
            <img src={RedWarningCircle} alt="Warning" />
          </div>
          <div className="inscription-step__error__message">
            <p>{errorMessage(error)}</p>
          </div>
        </div>
      )}
      <div className="default-step__button">
        <DefaultButton
          title="Confirmar código"
          onClick={handleConfirmRegistration}
          disabled={informationIsMissing}
        />
      </div>
      <div className="verification-step__resend">
        <DefaultButton
          title="Obtenha um novo código"
          type="quaternary"
          onClick={handleResendCode}
          disabled={timer > 0}
        />
      </div>
      {timer > 0 && (
        <div className="verification-step__timer">
          <p>Aguarde <b>{timer} segundos</b> para solicitar um novo código.</p>
        </div>
      )}
    </div>
  )
}

const RegistrationSuccess = () => {
  return (
    <div className="default-step">
      <div className="registration-success__icon">
        <img src={SuccessIcon} alt="Success" />
      </div>
      <h1 className="default-step__title registration-success__title">Cadastro concluído com sucesso!</h1>
      <p className="default-step__title__subtitle">Seu cadastro foi realizado com sucesso. Agora você está pronto para aproveitar todos os recursos da Lorie!</p>
      <div className="default-step__divisor" />
      <div className="next-steps">
        <p className="next-steps__title">Próximos passos:</p>
        <p className="next-steps__subtitle">Acesse sua conta para começar a explorar e aproveitar ao máximo o que temos a oferecer.</p>
        <DefaultButton
          title="Acessar conta"
          onClick={() => {
            window.location.assign("/login");
          }}
        />
      </div>
    </div>
  )
}

const NutritionistRegistration = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [nutritionist, setNutritionist] = useState({ account_type: 'NUTRITIONIST' });
  const [registration, setRegistration] = useState();

  const handleNextStep = () => {
    setCurrentStep((currStep) => currStep + 1);
  }

  const ComponentByStep = {
    1: <BasicInformationStep
      nutritionist={nutritionist}
      setNutritionist={setNutritionist}
      handleNext={handleNextStep}
    />,
    2: <EmailStep
      nutritionist={nutritionist}
      setNutritionist={setNutritionist}
      handleNext={handleNextStep}
    />,
    3: <PasswordStep
      nutritionist={nutritionist}
      setNutritionist={setNutritionist}
      handleNext={handleNextStep}
    />,
    4: <InscriptionStep
      nutritionist={nutritionist}
      setNutritionist={setNutritionist}
      setRegistration={setRegistration}
      handleNext={handleNextStep}
    />,
    5: <VerificationStep
      registration={registration}
      handleNext={handleNextStep}
    />,
    6: <RegistrationSuccess />,
  }

  return (
    <div className="registration">
      <div className="registration__header">
        <a href="/" className="registration__header__logo">
          <img src={TextLogo} alt="Logo" />
        </a>
        {currentStep <= 5 && (
          <StepMenu stepsAmount={5} currentStep={currentStep} setCurrentStep={setCurrentStep} />
        )}
        <div className="registration__header__button">
          <DefaultButton
            title="Já tenho conta"
            type="quaternary"
            onClick={() => {
              window.location.assign("/login");
            }}
          />
        </div>
      </div>
      <div className="registration__content">
        {ComponentByStep[currentStep]}
      </div>
    </div>
  )
};

export default NutritionistRegistration;

NutritionistRegistration.propTypes = {};
