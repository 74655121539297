import "./NutritionistProfile.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import loginService from "../../services/login-service";
import Avatar from "../../components/Avatar/Avatar";
import nutritionistService from "../../services/nutritionist-service";
import { GlobalContext } from "../GlobalContext";

import PinkShieldIcon from "../../assets/imgs/pink-shield-icon.svg";
import RoundStarIcon from "../../assets/imgs/round-star-icon.svg";
import PinkTooltipIcon from "../../assets/imgs/pink-tooltip-icon.svg";
import CloseIconGray from "../../assets/imgs/close-icon-gray.svg";
import DefaultButton from "../../components/DefaultButton/DefaultButton";
import AvatarSelector from "../../components/AvatarSelector/AvatarSelector";
import NotificationBar from "../../components/NotificationBar/NotificationBar";

const NutritionistProfile = () => {
  const [nutritionist, setNutritionist] = useState();
  const [showAvatarSelectorModal, setShowAvatarSelectorModal] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(true);
  const [notificationBar, setNotificationBar] = useState();
  const { setIsLoading } = useContext(GlobalContext);

  const fetchNutritionist = useCallback(async () => {
    setIsLoading(true);
    const userFromStorage = await loginService.getCurrentUser();
    const user = await nutritionistService.getNutritionistById(userFromStorage?.id);
    setNutritionist(user);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    Promise.resolve(fetchNutritionist());
  }, []);

  const EditableField = ({ label, value, description }) => {
    const [openTooltip, setOpenTooltip] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const [showEdit, setShowEdit] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const inputRef = useRef(null);
    const contentRef = useRef(null);

    useEffect(() => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.clientHeight);
      }
    }, [contentRef?.current?.clientHeight]);

    useEffect(() => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.clientHeight);
      }
    }, [openTooltip]);

    useEffect(() => {
      if (showEdit) {
        inputRef.current.focus();
      }
    }, [showEdit]);

    return (
      <>
        <div
          className="editable-field"
          onMouseLeave={() => setTimeout(() => {
            setOpenTooltip(false)
          }, 100)}
        >
          <div className="editable-field__info">
            <div className="editable-field__label">
              {label}
              {description && (
                <div href="javascript:;" className="editable-field__tooltip">
                  <a
                    href="javascript:;"
                    className="editable-field__tooltip__icon"
                    onMouseEnter={() => setOpenTooltip(true)}
                  >
                    <img src={PinkTooltipIcon} alt="Tooltip icon" />
                  </a>
                  {openTooltip && (
                    <div
                      className="editable-field__tooltip__content" ref={contentRef}
                      style={{ marginTop: `-${contentHeight + 30}px` }}
                    >
                      <p>{description}</p>
                      <a
                        href="javascript:;"
                        className="editable-field__tooltip__close"
                        onClick={() => setOpenTooltip(false)}
                      >
                        <img src={CloseIconGray} alt="Close icon" />
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="editable-field__value">
              <input
                ref={inputRef}
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                disabled={!showEdit}
              />
            </div>
          </div>
          <div className="editable-field__edit">
            <a
              href="javascript:;"
              onClick={() => setShowEdit(show => !show)}
            >{showEdit ? "Salvar" : "Editar"}</a>
          </div>
        </div>
      </>
    )
  };

  const AddableField = ({ label, value, defaultValue }) => {
    const [inputValue, setInputValue] = useState(value);
    const [showEdit, setShowEdit] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
      if (showEdit) {
        inputRef.current.focus();
      }
    }, [showEdit]);

    useEffect(() => {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }, [inputValue]);

    return (
      <div className="addable-field">
        <div className="addable-field__info">
          <p className="addable-field__label">{label}</p>
          <div className="addable-field__value">
            <textarea
              ref={inputRef}
              value={inputValue}
              placeholder={defaultValue}
              onChange={(e) => setInputValue(e.target.value)}
              onBlur={() => {
                setShowEdit(false);
              }}
              disabled={!inputValue && !showEdit}
            />
          </div>
        </div>
        {!inputValue && !showEdit && (
          <div className="addable-field__action">
            <a
              href="javascript:;"
              onClick={() => setShowEdit(show => !show)}
            >
              Adicionar
            </a>
          </div>
        )}
      </div>
    )
  }

  const formatDate = (date) => {
    if (!date) {
      return "";
    }

    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  const genderOptions = {
    MALE: "Masculino",
    FEMALE: "Feminino",
  }

  const formatSpecializations = (specializations) => {
    if (!specializations) {
      return "";
    }

    return specializations.map((specialization) => specialization.name).join(", ");
  }

  return (
    <>
      <div className="nutritionist-profile">
        <Breadcrumb
          previousPage={{ href: "/profile", title: "Meu perfil" }}
          currentPage={{ title: "Informações pessoais" }}
        />
        <div className="nutritionist-profile__title">
          <h1>Informações pessoais</h1>
        </div>
        {nutritionist && (
          <div className="nutritionist-profile__content">
            <div className="nutritionist-profile__personal-info">
              <EditableField label="Nome completo" value={nutritionist?.name} />
              <EditableField label="E-mail" value={nutritionist?.email} />
              <EditableField label="Data de nascimento" value={formatDate(nutritionist?.birth_date)} />
              <EditableField label="Gênero" value={genderOptions[nutritionist?.gender]} />
              <EditableField label="Especialidades" value={formatSpecializations(nutritionist?.specializations)} />
              <EditableField
                label="Inscrição"
                value={nutritionist?.professional_document?.registration_number}
                description="Número de registro no Conselho Regional de Nutricionistas"
              />
              <EditableField
                label="CRN"
                value={`CRN-${nutritionist?.professional_document?.crn}`}
                description="Conselho Regional de Nutricionistas"
              />
              <div className="nutritionist-profile__personal-info__warning">
                <div className="nutritionist-profile__personal-info__warning__icon">
                  <img src={PinkShieldIcon} alt="Shield icon" />
                </div>
                <p>Os pacientes só podem ver o nome e a especialidade do nutricionista. Outras informações são restritas por motivos de privacidade.</p>
              </div>
            </div>
            <div className="nutritionist-profile__preview">
              <div className="nutritionist-profile__preview__title">
                <h2>Como os pacientes veem você</h2>
              </div>
              <div className="nutritionist-profile__preview__content">
                <div className="nutritionist-profile__preview__content__avatar">
                  <Avatar 
                    user={nutritionist}
                    verified={true}
                    onClick={() => setShowAvatarSelectorModal(true)}
                  />
                  <div className="nutritionist-profile__preview__content__avatar__edit">
                    <a 
                      href="javascript:;"
                      onClick={() => setShowAvatarSelectorModal(true)}
                    >Trocar avatar</a>
                  </div>
                </div>
                <div className="nutritionist-profile__preview__content__name">
                  <p>{nutritionist?.name}</p>
                </div>
                <div className="nutritionist-profile__preview__content__specializations">
                  <p>{formatSpecializations(nutritionist?.specializations)}</p>
                </div>
                <div className="nutritionist-profile__preview__content__metrics">
                  <div className="nutritionist-profile__preview__content__metric">
                    <p className="nutritionist-profile__preview__content__value">11</p>
                    <p className="nutritionist-profile__preview__content__label">Comentários</p>
                  </div>
                  <div className="nutritionist-profile__preview__content__metric">
                    <div className="nutritionist-profile__preview__content__value">
                      {nutritionist.average_rating}
                      <img src={RoundStarIcon} alt="Star icon" />
                    </div>
                    <p className="nutritionist-profile__preview__content__label">Avaliações</p>
                  </div>
                  <div className="nutritionist-profile__preview__content__metric">
                    <p className="nutritionist-profile__preview__content__value">10</p>
                    <p className="nutritionist-profile__preview__content__label">Meses ativo</p>
                  </div>
                </div>
                <div className="nutritionist-profile__preview__content__addables">
                  <AddableField label="Descrição" value={nutritionist?.about} defaultValue="Sem descrição" />
                  <AddableField label="Endereço" value={nutritionist?.addresss} defaultValue="Sem endereço" />
                </div>
              </div>
              {showSuggestion && !nutritionist?.photo_url && (
                <div className="nutritionist-profile__suggestion">
                  <p className="nutritionist-profile__suggestion__title">Que tal adicionar um avatar ao seu perfil?</p>
                  <p className="nutritionist-profile__suggestion__description">Isso ajuda a tornar sua presença mais personalizada e amigável para seus pacientes</p>
                  <div className="nutritionist-profile__suggestion__actions">
                    <DefaultButton
                      title="Agora não"
                      type="secondary"
                      onClick={() => setShowSuggestion(false)}
                      size="small"
                      style="nutritionist-profile__suggestion__actions__button"
                    />
                    <DefaultButton
                      title="Escolher avatar"
                      type="primary"
                      onClick={() => setShowAvatarSelectorModal(true)}
                      size="small"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <AvatarSelector
        user={nutritionist}
        show={showAvatarSelectorModal}
        onClose={() => {
          setShowAvatarSelectorModal(false);
          fetchNutritionist();
        }}
        setNotificationBar={setNotificationBar}
      />
      <NotificationBar 
        show={notificationBar?.title}
        title={notificationBar?.title}
        durationInSeconds={4}
        onClose={() => setNotificationBar(null)}
      />
    </>
  );
};

export default NutritionistProfile;

NutritionistProfile.propTypes = {};
